import { IsingleMemberMeeting } from 'src/Types/module/meeting/singleMemberMeeting';
import { getStatusLabel } from 'src/Utils/Combined';
import MeetingUpdateForm from './MeetingUpdateForm';
import { Grid, Typography } from '@mui/material';
import MeetingItems from './MeetingItems';
import { Box } from '@mui/system';
import moment from 'moment';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MemberAttendance from './MemberAttendance';
import { useSearchParams } from 'react-router-dom';
import PdfAndImageView from 'src/components/pdfAndImageView/PdfAndImageView';
import { CommonTypoGraphy } from 'src/components/pdfAndImageView/CommonTypoGraphy';
const BillingInfo = ({
  singleInvoiceDetails,
  editMode,
  handleEditModeChange,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
  editMode: boolean;
  handleEditModeChange: any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get('tab') || '1';
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');

  return (
    <div>
      <Box sx={{ mt: 3 }}>
        {!editMode ? (
          <>
            {' '}
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting Place:
                </Typography>
                {singleInvoiceDetails?.member_meeting_place}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting type:
                </Typography>
                {singleInvoiceDetails?.member_meeting_type
                  ?.charAt(0)
                  ?.toUpperCase() +
                  singleInvoiceDetails?.member_meeting_type?.slice(1)}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Created by:
                </Typography>
                {singleInvoiceDetails?.meeting_created_by_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting Status:
                </Typography>

                {getStatusLabel(
                  singleInvoiceDetails?.member_meeting_status.toString()
                )}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting Date:
                </Typography>
                {moment(singleInvoiceDetails?.member_meeting_date).format('LL')}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting time:
                </Typography>
                {moment(
                  singleInvoiceDetails?.member_meeting_time,
                  'HH:mm'
                ).format('hh:mm A')}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting end time:
                </Typography>
                {singleInvoiceDetails?.member_meeting_end_time
                  ? moment(
                      singleInvoiceDetails?.member_meeting_end_time,
                      'HH:mm'
                    ).format('hh:mm A')
                  : 'N/A'}
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Meeting create date:
                </Typography>
                {moment(singleInvoiceDetails?.member_meeting_created_at).format(
                  'LL'
                )}
              </Grid>

              {singleInvoiceDetails?.meeting_updated_by_name && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Meeting updated by:
                  </Typography>
                  {singleInvoiceDetails?.meeting_updated_by_name}
                </Grid>
              )}
              {singleInvoiceDetails?.member_meeting_img && (
                <Grid item xs={2} sm={4} md={4}>
                  {' '}
                  <CommonTypoGraphy
                    name='View meeting image'
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(singleInvoiceDetails?.member_meeting_img);
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={2} sm={4} md={4}>
                {' '}
                <CommonTypoGraphy
                  name='View QR Code'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      `member_meeting_qr/${singleInvoiceDetails?.member_meeting_qr_code_file}`
                    );
                  }}
                />
              </Grid>
              {singleInvoiceDetails?.member_meeting_details && (
                <Grid item xs={2} sm={4} md={6}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Meeting details:
                  </Typography>
                  {singleInvoiceDetails?.member_meeting_details}
                </Grid>
              )}
              {singleInvoiceDetails.member_meeting_remark && (
                <Grid item xs={2} sm={4} md={6}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Meeting Remark:
                  </Typography>
                  {singleInvoiceDetails?.member_meeting_remark}
                </Grid>
              )}
            </Grid>
            {/* Form input  */}
            <Box
              sx={{ width: '100%', typography: 'body1', paddingTop: '40px' }}
            >
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={(
                      _event: React.SyntheticEvent,
                      newValue: string
                    ) => {
                      setSearchParams({ tab: newValue });
                    }}
                    aria-label='lab API tabs example'
                  >
                    <Tab label='Meeting members' value='1' />
                    <Tab label='Attend members' value='2' />
                  </TabList>
                </Box>
                <TabPanel value='1'>
                  <MeetingItems singleInvoiceDetails={singleInvoiceDetails} />
                </TabPanel>
                <TabPanel value='2'>
                  <MemberAttendance
                    meetingName={singleInvoiceDetails?.member_meeting_title}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        ) : (
          <MeetingUpdateForm
            handleEditModeChange={handleEditModeChange}
            singleInvoiceDetails={singleInvoiceDetails}
          />
        )}
      </Box>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </div>
  );
};

export default BillingInfo;
