import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HttpClient } from 'src/Helpers/http/http';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import CreateExpense from '../components/CreateTraineeExpense';
import TraineeExpenseTableRow from '../components/TraineeExpenseTableRow';
import { ITraineeExpenseType } from '../types/traineeExpenseTypes';
import { DateRangePicker } from '@mui/lab';
import CreateTraineeExpense from '../components/CreateTraineeExpense';

const TraineeExpenseList = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [allExpense, setAllExpense] = useState<ITraineeExpenseType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [filterItem, setFilterItem] = useState({
    expense_no: '',
  });
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });

  const page = parseInt(searchParams.get('page'));

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/expense?CompanyProfileId=2&limit=${limit}&skip=${limit * page}`;
        if (filterItem.expense_no) {
          uri += `&expense_no=${filterItem?.expense_no}`;
        }
        if (startDate && endDate) {
          uri += `&start_date=${startDate.toISOString().split('T')[0]}&end_date=${
            endDate.toISOString().split('T')[0]
          }`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAllExpense(res.data);
          setLoading(false);
          setTotal(res.total);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit, filterItem.expense_no, endDate, startDate, refresh]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  // const handleStatus = (e: SelectChangeEvent<string>) => {
  //   searchParams.set("status", e.target.value);
  //   setSearchParams(searchParams);
  // };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  // const paginatedInvoice = applyPagination(allExpense, page, limit);

  const handelInputChange = debounce((value: string, key: string) => {
    setFilterItem((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }, 2000);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <TextField
                    label='Search by expense no'
                    id='standard-search'
                    type='search'
                    size='medium'
                    variant='outlined'
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) =>
                        handelInputChange(e.target.value, 'expense_no')
                      // setSearchInvoiceVal(e.target.value)
                    }
                  />
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label='Start Date'
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={'bold'}
                      variant='h6'
                      component='h6'
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label='End Date'
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                  <Button onClick={() => setOpen(true)} variant='contained'>
                    Create Expense
                  </Button>
                </Box>
              }
              title='Expense List'
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Expense No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Expense Head</TableCell>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>Account</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : allExpense.length ? (
                      <>
                        {allExpense.map((item) => (
                          <TraineeExpenseTableRow
                            key={item.id}
                            expenseData={item}
                            setAllExpense={setAllExpense}
                            allExpense={allExpense}
                            setRefresh={setRefresh}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Create Expense' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateTraineeExpense
                setRefresh={setRefresh}
                handelClose={() => setOpen(false)}
              />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeExpenseList;
