import { MutableRefObject } from 'react';
import { Box, Typography } from '@mui/material';

import atab_logo from '../../../../../../../assets/image/atab-logo.png';
import { IMoneyReceipt } from 'src/Types/module/common/commonTypes';
import moment from 'moment';
import { TDate } from '../CompanyMoneyreceipt';
import { addCommasToNumber } from 'src/Utils/numberFormat';

const PrintMoneyReceipt = ({
  componentRef,
  memberInvoice,
  companyName,
  date,
}: {
  componentRef: MutableRefObject<any>;
  memberInvoice: IMoneyReceipt[];
  companyName: string;
  date: {
    start_date: string;
    end_date: string;
  };
}) => {
  const totalAmount: any = memberInvoice?.reduce(
    (total, item) => total + Number(item.total_collected_amount),
    0
  );

  const amount = addCommasToNumber(totalAmount);

  return (
    <Box
      ref={componentRef}
      px={3}
      className='print-money-receipt'
      sx={{ position: 'relative', height: '100vh' }}
    >
      <Box px={4}>
        <Box sx={{ textAlign: 'center' }} pt={5}>
          <img src={atab_logo} alt='' height={40} width={150} />
          <Box>
            <Typography variant='h4' sx={{ fontWeight: 'bold' }} py={1}>
              Member Ledger Report
            </Typography>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {companyName}
            </Typography>
          </Box>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          pt={3}
        >
          <Box>
            <Typography variant='h5' py={0.5}>
              From :
              {date.start_date && moment(date.start_date).format('DD/MM/YYYY')}
            </Typography>
            <Typography variant='h5'>
              TO : {date.end_date && moment(date.end_date).format('DD/MM/YYYY')}
            </Typography>
          </Box>
          <Box>
            <Typography variant='h5'>
              Print: {moment(new Date()).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={2}>
        <table className='member-info-print'>
          <thead>
            <tr className='business-license-table-head'>
              <th>Date</th>
              <th>Voucher No</th>
              <th>Money Receipt No</th>
              <th> Particulars</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {memberInvoice.length ? (
              <>
                {memberInvoice.map((item, index: number) => {
                  return (
                    <tr className='business-license-table-body' key={index}>
                      <td style={{ fontSize: '12px' }}>
                        {moment(item.created_at).format('DD/MM/YYYY')}
                      </td>
                      <td style={{ fontSize: '12px' }}>{item.voucher_no}</td>
                      <td style={{ fontSize: '12px' }}>
                        {item.money_receipt_no}
                      </td>
                      <td
                        style={{
                          width: '365px',
                          fontSize: '13px',
                        }}
                      >
                        {item.description}
                      </td>
                      <td style={{ fontSize: '12px' }}>
                        {item.total_collected_amount}
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className='business-license-table-body'>
                  <td
                    colSpan={5}
                    style={{ padding: '10px', fontWeight: 'bold' }}
                  >
                    No data found
                  </td>
                </tr>
              </>
            )}
          </tbody>
          <tfoot>
            <tr className='business-license-table-body'>
              <th></th>
              <th></th>
              <th></th>
              <th id='total'>Total :</th>
              <td> {memberInvoice.length ? `${amount}.00` : ''}</td>
            </tr>
          </tfoot>
        </table>
      </Box>
      {/* sx={{ position: "absolute", bottom: 40 }} */}
      <Box sx={{ position: 'fixed', bottom: 20, left: 10 }}>
        <Typography>Developed by m360ict</Typography>
      </Box>
    </Box>
  );
};

export default PrintMoneyReceipt;
