import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { imgUrl } from 'src/Helpers/utils/Constant';

import {
  IjoinedMember,
  IsingleMemberMeeting,
} from 'src/Types/module/meeting/singleMemberMeeting';
import { TableNotFound } from 'src/Utils/TableLoader';
import CommonExcelGenerator from 'src/common/CommonExcelGenerator';
import CommonPdfGenerator from 'src/common/CommonPdfGenerator';

const MeetingItems = ({
  singleInvoiceDetails,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
}) => {
  const theme = useTheme();

  return (
    <>
      {singleInvoiceDetails?.member_meeting_type === 'all' ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant='h4'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            All member are invited
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CommonExcelGenerator
              ExcelHeading={`${singleInvoiceDetails.member_meeting_title}\n Time:${dayjs(singleInvoiceDetails?.member_meeting_date).format('DD-MMM-YYYY')} Place: ${singleInvoiceDetails?.member_meeting_place}`}
              excelName={'meeting_members'}
              excelTableHead={[
                'SL',
                'Agency Name',
                'Member Name',
                'Joining Time',
              ]}
              excelData={
                singleInvoiceDetails?.joinedMember?.length
                  ? singleInvoiceDetails?.joinedMember?.map((sData, index) => {
                      const data = {
                        SL: index + 1,
                        'Agency Name': sData.user_member_company_name,
                        'Member Name': sData.joined_member_name,
                        'Joining Time': moment(
                          sData?.member_meeting_joined_member_joined_time
                        ).format('LLL'),
                      };
                      return data;
                    })
                  : []
              }
            />
            <CommonPdfGenerator
              PDFFileName='meeting_members'
              fileHeader='Meeting member list of (ATAB)'
              PDFHeader={['SL', 'Agency Name', 'Member Name', 'Joining Time']}
              name={singleInvoiceDetails?.member_meeting_title}
              PDFData={
                singleInvoiceDetails?.joinedMember?.length
                  ? singleInvoiceDetails?.joinedMember?.map((sData, index) => {
                      const data = {
                        SL: index + 1,
                        'Agency Name': sData.user_member_company_name,
                        'Member Name': sData.joined_member_name,
                        'Joining Time': moment(
                          sData?.member_meeting_joined_member_joined_time
                        ).format('LLL'),
                      };
                      return data;
                    })
                  : []
              }
            />
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    size='small'
                  >
                    SL
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    align='center'
                    size='small'
                  >
                    Agency name
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    align='center'
                    size='small'
                  >
                    Member name
                  </TableCell>

                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    align='center'
                    size='small'
                  >
                    Joining time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleInvoiceDetails?.joinedMember?.length ? (
                  <>
                    {singleInvoiceDetails?.joinedMember?.map(
                      (details: IjoinedMember, index: number) => (
                        <TableRow key={index}>
                          <TableCell size='small'>{index + 1}</TableCell>
                          <TableCell size='small'>
                            {details?.user_member_company_name}
                          </TableCell>
                          <TableCell align='center' size='small'>
                            <Box
                              sx={{ display: 'flex', justifyContent: 'start' }}
                            >
                              <img
                                width={30}
                                height={30}
                                style={{
                                  borderRadius: '50%',
                                  marginRight: '10px',
                                }}
                                src={
                                  details?.joined_member_photo
                                    ? `${imgUrl}/${details.joined_member_photo}`
                                    : '/static/images/avatars/avatar2.png'
                                }
                                alt='Meeting Member Photo'
                              />

                              {details?.joined_member_name && (
                                <Typography
                                  variant='body1'
                                  fontWeight='bold'
                                  color='text.primary'
                                  gutterBottom
                                  noWrap
                                >
                                  <Link
                                    to={`/member-list/${details?.joined_member_id}`}
                                  >
                                    {' '}
                                    {details?.joined_member_name}
                                  </Link>
                                </Typography>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell align='center' size='small'>
                            {moment(
                              details?.member_meeting_joined_member_joined_time
                            ).format('LLL')}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default MeetingItems;
