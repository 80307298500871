import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from 'react';
import moment from 'moment';

const DynamicInvoiceField = ({
  register,
  allPaymentableItem,
  index,
  control,
  remove,
  fields,
  setValue,
  memberList,
}: any) => {
  const itemID = useWatch({
    control,
    name: `items.${index}.id`,
  });
  const quantity = useWatch({
    control,
    name: `items.${index}.quantity`,
  });

  const yearSelect = useWatch({
    control,
    name: `items.${index}.year`,
  });
  const member_id = useWatch({
    control,
    name: `member_id`,
  });
  useEffect(() => {
    const findPaymentItem = allPaymentableItem?.find(
      (item) => item?.atab_paymentable_item_id === Number(itemID)
    );

    if (findPaymentItem?.atab_paymentable_item_id) {
      setValue(
        `items.${index}.amount`,
        Number(findPaymentItem?.atab_paymentable_item_bdt_amount)
      );
    } else {
      setValue(`items.${index}.amount`, 0);
    }
  }, [itemID, quantity]);

  useEffect(() => {
    if (!member_id) {
      setValue(`items.${index}.year`, []);
      setValue(`items.${index}.id`, []);
    }
  }, [member_id]);
  useEffect(() => {
    if (
      (itemID === 2 || itemID === 41 || itemID === 42) &&
      memberList?.length === 1 &&
      memberList[0]?.user_member_membership_payment_expiry_date
    ) {
      //current year to increment more two years
      const year = Array.from({ length: 3 }, (x, index) => ({
        label: String(
          Number(
            moment(memberList[0]?.user_member_membership_payment_expiry_date)
              .utc()
              .year()
          ) +
            index +
            1
        ),
        value: String(
          Number(
            moment(memberList[0]?.user_member_membership_payment_expiry_date)
              .utc()
              .year()
          ) +
            index +
            1
        ),
      }));
      if (year?.length) {
        setValue(`items.${index}.year`, year);
      }
    }
    if (
      itemID === 3 &&
      memberList?.length === 1 &&
      memberList[0]?.user_member_id_card_expiry_date //for id card
    ) {
      //current year to increment more two years
      const year = Array.from({ length: 1 }, (x, index) => ({
        label: String(
          Number(
            moment(memberList[0]?.user_member_id_card_expiry_date).utc().year()
          ) +
            index +
            1
        ),
        value: String(
          Number(
            moment(memberList[0]?.user_member_id_card_expiry_date).utc().year()
          ) +
            index +
            1
        ),
      }));
      if (year?.length) {
        setValue(`items.${index}.year`, year);
      }
    }
  }, [itemID]);

  useEffect(() => {
    if (
      (itemID === 2 || itemID === 41 || itemID === 42) &&
      yearSelect?.length === 1 &&
      !yearSelect[0]?.loopAdd &&
      !memberList[0]?.user_member_membership_payment_expiry_date
    ) {
      //current year to increment more two years
      const year = Array.from({ length: 3 }, (x, index) => ({
        label: String(Number(yearSelect[0]?.value) + index),
        value: String(Number(yearSelect[0]?.value) + index),
        loopAdd: true,
      }));
      if (year?.length) {
        setValue(`items.${index}.year`, year);
      }
    }
  }, [yearSelect?.length]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems='center'
        sx={{
          margin: `0px 0 15px 0`,
          background: '#f8f9f9',
          borderRadius: '15px',
          py: '5px',
        }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <Controller
            control={control}
            name={`items.${index}.id`}
            render={({ field }) => (
              <FormControl sx={{ mt: 1 }} fullWidth required margin='normal'>
                <InputLabel id={`select-item-label-${index}`}>
                  Select Item
                </InputLabel>
                <Select
                  labelId={`select-item-label-${index}`}
                  id={`select-item-${index}`}
                  {...field} // Integrates React Hook Form's control
                  label='Expense Head'
                >
                  {allPaymentableItem?.map((item) => (
                    <MenuItem
                      key={item.atab_paymentable_item_id}
                      value={item.atab_paymentable_item_id}
                    >
                      {item.atab_paymentable_item_name} (
                      {item.atab_paymentable_item_bdt_amount} bdt)
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <TextField
            fullWidth
            required
            label='Amount'
            type='number'
            variant='outlined'
            {...register(`items.${index}.amount`)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {!(itemID === 2 || itemID === 41 || itemID === 42 || itemID === 3) && (
          <Grid item xs={12} md={12} lg={2}>
            <Controller
              control={control}
              name={`items.${index}.quantity`}
              defaultValue={1} // Ensures the default value is always 1
              render={({ field }) => {
                return (
                  <TextField
                    fullWidth
                    label='Quantity'
                    type='number'
                    variant='outlined'
                    value={field.value} // Fallback to 1 if value is undefined or null
                    onChange={field.onChange} // Ensure React Hook Form tracks changes
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
        )}

        {(itemID === 2 || itemID === 41 || itemID === 42 || itemID === 3) && (
          <Grid item xs={12} md={12} lg={5}>
            <Controller
              control={control}
              name={`items.${index}.year`}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={Array.from({ length: 100 }, (_, i) => {
                    const year = 2000 + i;
                    return { label: `${year}`, value: `${year}` };
                  })}
                  getOptionLabel={(option) => option.label}
                  value={field.value || []}
                  onChange={(e, newValue) => {
                    const uniqueValues = Array.from(
                      new Set(newValue.map((item) => item.value))
                    ).map((value) =>
                      newValue.find((item) => item.value === value)
                    );

                    field.onChange(uniqueValues);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Select years'
                      placeholder='More...'
                    />
                  )}
                />
              )}
            />
          </Grid>
        )}

        {fields.length > 1 && (
          <Grid item>
            <IconButton onClick={() => remove(index)} color='error'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DynamicInvoiceField;

//old--------------------------

// import {
//   Autocomplete,
//   FormControl,
//   Grid,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
// } from '@mui/material';
// import { Controller, useWatch } from 'react-hook-form';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useEffect } from 'react';
// const DynamicInvoiceField = ({
//   register,
//   allPaymentableItem,
//   index,
//   item,
//   control,
//   remove,
//   fields,
//   setValue,
// }: any) => {
//   const itemID = useWatch({
//     control,
//     name: `items.${index}.id`,
//   });

//   useEffect(() => {
//     const findPaymentItem = allPaymentableItem?.find(
//       (item) => item?.atab_paymentable_item_id === Number(itemID)
//     );

//     if (findPaymentItem?.atab_paymentable_item_id) {
//       setValue(
//         `items.${index}.amount`,
//         findPaymentItem?.atab_paymentable_item_bdt_amount
//       );
//     } else {
//       setValue(`items.${index}.amount`, 0);
//     }
//   }, [itemID]);
//   return (
//     <>
//       <Grid
//         container
//         spacing={2}
//         alignItems='center'
//         sx={{ marginBottom: '14px' }}
//       >
//         <Grid item xs={12} md={12} lg={4}>
//           <FormControl sx={{ mt: 1 }} fullWidth required margin='normal'>
//             <InputLabel id='demo-simple-select-label'>Select Item</InputLabel>
//             <Select
//               labelId='demo-simple-select-label'
//               id='demo-simple-select'
//               {...register(`items.${index}.id`)}
//               label='Expense Head'
//             >
//               {allPaymentableItem?.map((item) => {
//                 return (
//                   <MenuItem
//                     key={item.atab_paymentable_item_id}
//                     value={item.atab_paymentable_item_id}
//                   >
//                     {item.atab_paymentable_item_name} (
//                     {item.atab_paymentable_item_bdt_amount} bdt)
//                   </MenuItem>
//                 );
//               })}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} md={12} lg={2}>
//           <TextField
//             fullWidth
//             required
//             // defaultValue={0}
//             label='Amount'
//             type='number'
//             variant='outlined'
//             {...register(`items.${index}.amount`)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Grid>
//         {/* {item.atab_paymentable_item_id == 2 && ( */}
//         {itemID === 2 && (
//           <Grid item xs={12} md={12} lg={5}>
//             <Controller
//               control={control}
//               name={`items.${index}.year`}
//               render={({ field }) => (
//                 <Autocomplete
//                   multiple
//                   options={Array.from({ length: 100 }, (_, i) => {
//                     const year = 2000 + i;
//                     return { label: `${year}`, value: `${year}` };
//                   })}
//                   getOptionLabel={(option) => option.label}
//                   value={field.value || []}
//                   onChange={(e, newValue) => {
//                     const uniqueValues = Array.from(
//                       new Set(newValue.map((item) => item.value))
//                     ).map((value) =>
//                       newValue.find((item) => item.value === value)
//                     );

//                     field.onChange(uniqueValues);
//                   }}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       variant='outlined'
//                       label='Select years'
//                       placeholder='More...'
//                     />
//                   )}
//                 />
//               )}
//             />
//           </Grid>
//         )}

//         {/* )} */}
//         {fields.length > 1 && (
//           <Grid item xs={1}>
//             <IconButton onClick={() => remove(index)} color='error'>
//               <DeleteIcon />
//             </IconButton>
//           </Grid>
//         )}
//       </Grid>
//     </>
//   );
// };

// export default DynamicInvoiceField;
