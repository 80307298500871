import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { url } from 'src/Helpers/utils/Constant';
import { IMeetingAttendMember } from 'src/Types/module/meeting/singleMemberMeeting';
import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import CommonExcelGenerator from 'src/common/CommonExcelGenerator';
import CommonPdfGenerator from 'src/common/CommonPdfGenerator';

const MemberAttendance = ({ meetingName }: { meetingName: string }) => {
  const theme = useTheme();
  const { meetingId } = useParams();
  const [attendMembers, setAttendMembers] = useState<IMeetingAttendMember[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/member/meeting/qr-code/scanned/member/${meetingId}`
        );
        if (data.success) {
          setAttendMembers(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [meetingId]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <CommonExcelGenerator
          excelName={'attend_members'}
          excelTableHead={['SL', 'Member Name', 'Entry Time']}
          excelData={
            attendMembers?.length
              ? attendMembers?.map((sData, index) => {
                  const data = {
                    SL: index + 1,
                    'Member Name': sData.member_name,
                    'Entry Time': moment(sData?.scanned_time).format('LLL'),
                  };
                  return data;
                })
              : []
          }
        />
        <CommonPdfGenerator
          PDFFileName='attend_members'
          fileHeader='Meeting attend member list of (ATAB)'
          PDFHeader={['ID', 'Member Name', 'Entry Time']}
          name={meetingName}
          PDFData={
            attendMembers?.length
              ? attendMembers?.map((sData) => {
                  const data = {
                    ID: sData.scanned_id,
                    'Member Name': sData.member_name,
                    'Entry Time': moment(sData?.scanned_time).format('LLL'),
                  };
                  return data;
                })
              : []
          }
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: theme.colors.alpha.black[100] }}
                size='small'
              >
                SL
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.black[100] }}
                align='center'
                size='small'
              >
                Member name
              </TableCell>

              <TableCell
                sx={{ color: theme.colors.alpha.black[100] }}
                align='center'
                size='small'
              >
                entry time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : (
              <>
                {attendMembers?.length ? (
                  <>
                    {attendMembers.map((details, index: number) => (
                      <TableRow key={index}>
                        <TableCell size='small'>{index + 1}</TableCell>

                        <TableCell align='center' size='small'>
                          <Link to={`/member-list/${details?.member_id}`}>
                            {' '}
                            {details?.member_name}
                          </Link>
                        </TableCell>
                        <TableCell align='center' size='small'>
                          {moment(details?.scanned_time).format('LT')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>{' '}
      <Box p={2}>
        <TablePagination
          component='div'
          count={attendMembers?.length}
          onPageChange={(_event: any, newPage: number) => setPage(newPage)}
          onRowsPerPageChange={(event) =>
            setLimit(parseInt(event.target.value))
          }
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </div>
  );
};

export default MemberAttendance;
