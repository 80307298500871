import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import { Divider } from '@mui/material';
import { ISingleTradeLicense } from 'src/Types/module/UpdateRequest/RequestTradeLicense';
import moment from 'moment';

interface IProps {
  preTradeLicenseInfo: ISingleTradeLicense;
}
const CurrentTradeLicenseInfo = ({ preTradeLicenseInfo }: IProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h4' fontSize={21}>
            Current information
          </Typography>
        }
      />

      <TableContainer sx={{ mb: 5 }}>
        <Table aria-label='custom pagination table'>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                Trade license
              </TableCell>
              <TableCell align='right'>
                {preTradeLicenseInfo?.company_trade_license}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                Company bin number
              </TableCell>
              <TableCell align='right'>
                {' '}
                {preTradeLicenseInfo?.company_bin_number}
              </TableCell>
            </TableRow>
            {preTradeLicenseInfo.company_bin_file && (
              <TableRow>
                <TableCell component='th' scope='row'>
                  Company bin file
                </TableCell>
                <TableCell align='right'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${imgUrl}/${preTradeLicenseInfo.company_bin_file}`}
                  >
                    {preTradeLicenseInfo.company_bin_file}
                  </a>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell component='th' scope='row'>
                Company tin number
              </TableCell>
              <TableCell align='right'>
                {preTradeLicenseInfo?.company_tin_number}
              </TableCell>
            </TableRow>
            {preTradeLicenseInfo.company_tin_file && (
              <TableRow>
                <TableCell component='th' scope='row'>
                  Proof of Submission of Tax Return (Acknowledgement Receipt)
                </TableCell>
                <TableCell align='right'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${imgUrl}/${preTradeLicenseInfo.company_tin_file}`}
                  >
                    {preTradeLicenseInfo.company_tin_file}
                  </a>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell component='th' scope='row'>
                Company trade license expiry date
              </TableCell>
              <TableCell align='right'>
                {moment(
                  preTradeLicenseInfo?.company_trade_license_expiry_date
                ).format('LL')}
              </TableCell>
            </TableRow>

            {preTradeLicenseInfo.company_trade_license_file && (
              <TableRow>
                <TableCell component='th' scope='row'>
                  Company tarde license file
                </TableCell>
                <TableCell align='right'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`${imgUrl}/${preTradeLicenseInfo.company_trade_license_file}`}
                  >
                    {preTradeLicenseInfo.company_trade_license_file}
                  </a>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentTradeLicenseInfo;
