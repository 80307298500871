import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HttpClient } from 'src/Helpers/http/http';
import Toaster from 'src/Toaster/Toaster';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { CustomTextArea } from 'src/components/CustomTextArea';
import { IInspection } from './type/inspectionType';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const UpdateInspectionInformation = ({
  memberId,
  inspectionData,
  handelClose,
  reload,
}: {
  memberId: string;
  inspectionData: IInspection;
  handelClose: any;
  reload: any;
}) => {
  const { user } = useAuthContext();
  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(inspectionData?.inspection_report_date)
  );
  const [otherCompany, setOtherCompany] = useState(
    inspectionData?.others_agency?.length
      ? inspectionData?.others_agency.map((other) => {
          return {
            other_travel_agency_name: other?.other_travel_agency_name,
            other_travel_agency_location: other?.other_travel_agency_location,
            inspection_report_others_agency_id:
              other?.inspection_report_others_agency_id,
          };
        })
      : [{ other_travel_agency_name: '', other_travel_agency_location: '' }]
  );

  const [deleted, setDeleted] = useState([]);

  const handleChangeInput = (index, event) => {
    const values = [...otherCompany];
    values[index][event.target.name] = event.target.value;
    setOtherCompany(values);
  };
  const handleAddFields = () => {
    setOtherCompany([
      ...otherCompany,
      { other_travel_agency_name: '', other_travel_agency_location: '' },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...otherCompany];
    values.splice(index, 1);
    setOtherCompany(values);
  };

  const onSubmit = async (data: any) => {
    // data.user_member_id = memberId;

    // data.application_forwarder_id = forwarderId;
    setLoading(true);
    const formData = new FormData();

    if (otherCompany?.length) {
      formData.append('others_agency', JSON.stringify(otherCompany));
    }

    if (deleted?.length) {
      formData.append('del', JSON.stringify(deleted));
    }
    for (const key in data) {
      if (data[key]) {
        if (
          key === 'secretary_signature' ||
          key === 'chairman_signature' ||
          key === 'inspector_seal_signature_1' ||
          key === 'inspector_seal_signature_2'
        ) {
          if (data[key][0]) {
            formData.append(key, data[key][0]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      const res: any = await HttpClient.patch(
        `/api/inspcetion-report/${memberId}`,
        formData
      );

      if (res.success) {
        Toaster().fire({
          icon: 'success',
          title: res.message,
        });
        reload();
        handelClose();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  const telephoneConnection = watch('telephone_connection');
  const skillEmployee =
    watch('skill_employee') || String(inspectionData?.skill_employee);
  const ownOtherTravelAgency = watch('own_other_travel_agency');

  return (
    <div>
      {' '}
      <Box>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
          <Grid container spacing={2} columns={24}>
            {' '}
            <Grid item xs={24} md={12} lg={12}>
              {' '}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label='Update meeting date'
                  format='MM/DD/YYYY'
                  sx={{ width: '100%' }}
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e)}
                />
              </LocalizationProvider>
              {/* <TextField
                value={inspectionData?.inspection_report_date}
                {...register("inspection_report_date")}
                id="outlined-basic"
                variant="outlined"
                label="Inspection date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Is the travel agency license up to date?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.agency_license_update}
                  {...register('agency_license_update')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Is the travel agency license up to date?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Is the trade license up to date or not?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.trade_license_update}
                  {...register('trade_license_update')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Is the trade license up to date or not?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <TextField
                defaultValue={inspectionData?.total_office_room}
                {...register('total_office_room')}
                id='outlined-basic'
                variant='outlined'
                label='Enter office room'
                type='number'
                fullWidth
              />
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <TextField
                defaultValue={inspectionData?.total_office_area}
                {...register('total_office_area')}
                id='outlined-basic'
                variant='outlined'
                label='Enter office size'
                type='text'
                fullWidth
              />
            </Grid>{' '}
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Is there a telephone connection?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.telephone_connection}
                  {...register('telephone_connection')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Is the trade license up to date or not?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>{' '}
            {telephoneConnection === '1' && (
              <Grid item xs={24} md={12} lg={12}>
                <TextField
                  defaultValue={inspectionData?.total_telephone_connection}
                  {...register('total_telephone_connection')}
                  id='outlined-basic'
                  variant='outlined'
                  label='Number of telephone connection'
                  type='number'
                  fullWidth
                />
              </Grid>
            )}{' '}
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Is there a website?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.fax_connection}
                  {...register('fax_connection')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label=' Is there a website?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Is there an e-mail connection?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.email_connection}
                  {...register('email_connection')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Is there an e-mail connection?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Whether there is skilled manpower or not
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.skill_employee}
                  {...register('skill_employee')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Whether there is skilled manpower or not'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {skillEmployee === '1' ? (
              <Grid item xs={24} md={24} lg={24}>
                {' '}
                <CustomTextArea
                  defaultValue={inspectionData?.skill_employee_details}
                  placeholder='Enter description'
                  name={'skill_employee_details'}
                  register={register}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={24} md={12} lg={12}>
              <TextField
                defaultValue={inspectionData?.total_staf}
                {...register('total_staf')}
                id='outlined-basic'
                label='Number of staff'
                placeholder='Number of staff'
                variant='outlined'
                type='number'
                fullWidth
              />
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Are there visible sign boards?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.see_able_sign_board}
                  {...register('see_able_sign_board')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Are there visible sign boards?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>{' '}
            <Grid item xs={24} md={12} lg={12}>
              <TextField
                defaultValue={inspectionData?.bank_details}
                {...register('bank_details')}
                id='outlined-basic'
                label='Bank name, branch and account no'
                placeholder='Bank name, branch and account no'
                variant='outlined'
                type='text'
                fullWidth
              />
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Have you ever defaulted?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.atab_defilter}
                  {...register('atab_defilter')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Have you ever defaulted??'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Whether or not the proprietor of any other travel agency?
                </InputLabel>
                <Select
                  defaultValue={inspectionData?.own_other_travel_agency?.toString()}
                  {...register('own_other_travel_agency')}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Whether or not the proprietor of any other travel agency?'
                >
                  <MenuItem value={'1'}>YES</MenuItem>
                  <MenuItem value={'0'}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>{' '}
            {ownOtherTravelAgency === '1' ||
            inspectionData?.own_other_travel_agency ? (
              <Grid item xs={24} md={24} lg={24}>
                {otherCompany.map((inputField, index) => {
                  return (
                    <Grid key={index} container spacing={2} columns={24} pb={2}>
                      <Grid item xs={24} md={10} lg={10}>
                        {' '}
                        <TextField
                          fullWidth
                          name='other_travel_agency_name'
                          label='Agency name'
                          variant='outlined'
                          value={inputField.other_travel_agency_name}
                          onChange={(event) => handleChangeInput(index, event)}
                        />
                      </Grid>

                      <Grid item xs={24} md={10} lg={10}>
                        {' '}
                        <TextField
                          fullWidth
                          name='other_travel_agency_location'
                          label='Agency address'
                          variant='outlined'
                          value={inputField.other_travel_agency_location}
                          onChange={(event) => handleChangeInput(index, event)}
                        />
                      </Grid>
                      <Grid item xs={24} md={4} lg={4}>
                        {index > 0 && index !== 0 ? (
                          <IconButton
                            onClick={() => {
                              handleRemoveFields(index);

                              setDeleted([
                                ...deleted,
                                inputField?.inspection_report_others_agency_id,
                              ]);
                            }}
                            title='Remove'
                          >
                            <RemoveIcon />
                          </IconButton>
                        ) : (
                          ''
                        )}

                        {index === 0 && (
                          <IconButton
                            onClick={() => {
                              handleAddFields();
                            }}
                            title='Add'
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              ''
            )}{' '}
            {user?.user_admin_role === 'Super Admin' ||
            user?.user_admin_role === 'Admin' ? (
              <>
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    defaultValue={inspectionData?.inspector_name_1}
                    id='outlined-basic'
                    {...register('inspector_name_1')}
                    label='First inspector name'
                    placeholder='First inspector name'
                    variant='outlined'
                    type='text'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    defaultValue={inspectionData?.inspector_name_2}
                    id='outlined-basic'
                    {...register('inspector_name_2')}
                    label='Second inspector name'
                    placeholder='Second inspector name'
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>{' '}
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    id='outlined-basic'
                    {...register('inspector_seal_signature_1')}
                    label='First inspector seal & signature (jpg/png/jpeg file)'
                    placeholder='First inspector seal & signature'
                    variant='outlined'
                    type='file'
                    fullWidth
                    InputProps={{
                      inputProps: {
                        accept: 'image/jpeg,image/png,image/jpg',
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    // required
                  />
                </Grid>
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    id='outlined-basic'
                    {...register('inspector_seal_signature_2')}
                    label='Second inspector seal & signature (jpg/png/jpeg file)'
                    placeholder='Second inspector seal & signature'
                    variant='outlined'
                    type='file'
                    fullWidth
                    InputProps={{
                      inputProps: {
                        accept: 'image/jpeg,image/png,image/jpg',
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    // required
                  />
                </Grid>
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    id='outlined-basic'
                    {...register('secretary_signature')}
                    label='Secretary seal & signature (jpg/png/jpeg file)'
                    placeholder='Secretary seal & signature'
                    variant='outlined'
                    type='file'
                    InputProps={{
                      inputProps: {
                        accept: 'image/jpeg,image/png,image/jpg',
                      },
                    }}
                    // InputProps={{ accept: ".pdf,.doc,.docx" }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    // required
                  />
                </Grid>
                <Grid xs={24} md={12} lg={12} item>
                  <TextField
                    id='outlined-basic'
                    {...register('chairman_signature')}
                    label='Chairman seal & signature (jpg/png/jpeg file)'
                    placeholder='Chairman seal & signature'
                    variant='outlined'
                    type='file'
                    fullWidth
                    InputProps={{
                      inputProps: {
                        accept: 'image/jpeg,image/png,image/jpg',
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    // required
                  />
                </Grid>
              </>
            ) : (
              ''
            )}
            <Grid xs={24} md={12} lg={12} item>
              {' '}
              <CustomTextArea
                defaultValue={inspectionData?.office_building_details}
                placeholder='Description of office building'
                name={'office_building_details'}
                register={register}
              />
            </Grid>
            <Grid item xs={24} md={12} lg={12}>
              {' '}
              <CustomTextArea
                defaultValue={inspectionData?.remarks}
                placeholder='Enter comment'
                name={'remarks'}
                register={register}
              />
            </Grid>
          </Grid>{' '}
          <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
            {loading ? (
              <Button
                disabled
                variant='contained'
                disableElevation
                className='add-remove-button'
              >
                loading..
              </Button>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className='add-remove-button'
              >
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateInspectionInformation;
