import { Divider, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { ITrialBalanceReportType } from "./trialBalanceReportTypes";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
type IProps = {
  singleTrialBalanceData: ITrialBalanceReportType;
};
const TrialBalanceReportTableRow = ({ singleTrialBalanceData }: IProps) => {
  const {
    TotalCredit,
    TotalDebit,
    Name,
    AccHeadGroup,
    AccHeadAndSubGroup,
    Code,
  } = singleTrialBalanceData || {};

  return (
    <TableRow hover>
      <TableCell>
        <SimpleTreeView>
          <TreeItem
            itemId="grid"
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span>
                    {Name} ({Code})
                  </span>
                </div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{TotalDebit}</span>
                  <span>{TotalCredit}</span>
                </div>
              </div>
            }
          >
            {AccHeadGroup?.map((item) => {
              if (item?.AccHeadAndSubGroup?.length) {
                return (
                  <SimpleTreeView key={item.Id}>
                    <TreeItem
                      itemId="grid"
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>
                              {item.Name} ({item.Code})
                            </span>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{item.TotalDebit}</span>
                            <span>{item.TotalCredit}</span>
                          </div>
                        </div>
                      }
                    >
                      {item?.AccHeadAndSubGroup?.map((item) => {
                        return (
                          <TreeItem
                            itemId={`${item.Id}`}
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <span>
                                    {item.Name} ({item.Code})
                                  </span>
                                </div>
                                <div
                                  style={{
                                    width: "30%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{item.TotalDebit}</span>
                                  <span>{item.TotalCredit}</span>
                                </div>
                              </div>
                            }
                          />
                        );
                      })}
                    </TreeItem>
                  </SimpleTreeView>
                );
              } else {
                return (
                  <SimpleTreeView>
                    <TreeItem
                      itemId={`${item.Id}`}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>
                              {item.Name} ({item.Code})
                            </span>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{item.TotalDebit}</span>
                            <span>{item.TotalCredit}</span>
                          </div>
                        </div>
                      }
                    />
                  </SimpleTreeView>
                );
              }
            })}
          </TreeItem>
        </SimpleTreeView>
      </TableCell>
    </TableRow>
  );
};

export default TrialBalanceReportTableRow;
