import { useRef } from 'react';
import { TableRow, TableCell, Tooltip, IconButton, Box } from '@mui/material';
import { IMoneyReceipt } from 'src/Types/module/common/commonTypes';
import moment from 'moment';
import useTheme from '@mui/material/styles/useTheme';
import PrintSingleMoneyReceipt from './PrintInformationForm/PrintSingleMoneyReceipt/PrintSingleMoneyReceipt';
import { Link } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';

const ShowMoneyReceipt = ({ details }: { details: IMoneyReceipt }) => {
  const theme = useTheme();
  const componentRef = useRef(null);

  return (
    <>
      <TableRow hover>
        <TableCell>
          {moment(details.created_at).format('DD-MMM-YYYY')}
        </TableCell>
        <TableCell>{details.money_receipt_no}</TableCell>
        <TableCell>{details.user_member_company_name}</TableCell>
        <TableCell>
          <p>
            {details.account_name}{' '}
            <span style={{ color: '#999999' }}>({details.payment_method})</span>
          </p>
        </TableCell>
        <TableCell>{details.total_collected_amount}</TableCell>
        <TableCell>
          <Tooltip title='View' arrow>
            <Link to={`/money-receipt/list/${details.id}`}>
              <IconButton
                sx={{
                  '&:hover': {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color='inherit'
                size='small'
              >
                <Visibility fontSize='small' />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>

      {/* <Box display={'none'}>
        <PrintSingleMoneyReceipt
          componentRef={componentRef}
          singleMoneyReceipt={singleMoneyReceipt}
        />
      </Box> */}
    </>
  );
};

export default ShowMoneyReceipt;
