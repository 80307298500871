import { Box, Container } from '@mui/system';
import { MutableRefObject } from 'react';
import MoneyReceiptDemo from './MoneyReceiptDemo';

const PrintSingleMoneyReceipt = ({
  componentRef,
  singleMoneyReceipt,
}: {
  componentRef: MutableRefObject<any>;
  singleMoneyReceipt: any;
}) => {
  return (
    <Box ref={componentRef} sx={{ pt: 2 }}>
      <Container
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        maxWidth='md'
      >
        <MoneyReceiptDemo singleMoneyReceipt={singleMoneyReceipt} />
        <hr
          style={{
            width: '100%',
            borderTop: '2px dashed black',
            marginTop: '30px',
          }}
        />
        <MoneyReceiptDemo singleMoneyReceipt={singleMoneyReceipt} />
      </Container>
    </Box>
  );
};

export default PrintSingleMoneyReceipt;
