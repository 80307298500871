import { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import {
  IMoneyReceipt,
  IResponseMoneyReceipt,
} from 'src/Types/module/common/commonTypes';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import PrintMoneyReceipt from './PrintInformationForm/PrintMoneyReceipt';
import { HttpClient } from 'src/Helpers/http/http';
import ShowMoneyReceipt from './ShowMoneyReceipt';
import CommonDateRangePicker from 'src/common/CommonDateRangePicker';
import dayjs from 'dayjs';

export type TDate = {
  startDate: Date | null;
  endDate: Date | null;
};
const CompanyMoneyreceipt = ({ companyName }: { companyName: string }) => {
  const { memberId } = useParams();
  const [memberInvoice, setMemberInvoice] = useState<IMoneyReceipt[]>([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<TDate>({ startDate: null, endDate: null });
  const theme = useTheme();
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${memberId}_transaction `,
  });
  const [dateRange, setDateRange] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: '',
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  console.log(dateRange);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/admin/money-receipt?member_id=${memberId}`;
        if (dateRange.start_date && dateRange.end_date) {
          uri += `&from_date=${dateRange.start_date}&to_date=${dateRange.end_date}`;
        }

        const data: IResponseMoneyReceipt = await HttpClient.get(uri);
        if (data.success) {
          setMemberInvoice(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, dateRange]);

  return (
    <Box maxWidth={'100%'}>
      <Box>
        <Grid container justifyContent={'end'} sx={{ paddingBottom: '10px' }}>
          <Grid item xs={6}>
            <CommonDateRangePicker
              setDateRange={setDateRange}
              dateRange={dateRange}
            />
          </Grid>

          <Grid item>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Tooltip title='Print money receipt'>
                <IconButton onClick={handlePrint}>
                  <PrintIcon
                    sx={{
                      cursor: 'pointer',
                      color: theme.colors.primary.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Money Receipt no</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {memberInvoice.length ? (
                    <>
                      {memberInvoice.map(
                        (details: IMoneyReceipt, index: number) => (
                          <ShowMoneyReceipt details={details} key={index} />
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <TableNotFound />
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      <Box display={'none'}>
        <PrintMoneyReceipt
          componentRef={componentRef}
          memberInvoice={memberInvoice}
          companyName={companyName}
          date={dateRange}
        />
      </Box>
    </Box>
  );
};

export default CompanyMoneyreceipt;
