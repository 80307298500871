import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import PrivateRoute from './Reducers/AuthReducer/PrivateRoute';
import ForgetPassword from './content/pages/Forget/ForgetPassword';
import ChangePassword from './content/pages/changePassword/ChangePassword';
import OtpSend from './content/pages/otp/OtpSend';
import CompanyInfoDetails from './content/modules/Members/subModules/memberList/singleMember';
import SingleMeetingPage from './content/modules/Members/Meeting/singleMeeting/Index';
import PaymentableItemList from './content/modules/PaymentableItem/AllPaymentableItem/PaymentableItemList';
import SingleAdmin from './content/modules/Admin/singleAdmin/SingleAdmin';
import FairStallList from './content/modules/FairStall/FairStallList';
import FairInvoice from './content/modules/FairInvoice/FairInvoiceShow';
import FairInvoiceByFairMember from './content/modules/FairInvoice/FairInvoiceByFairMember/Index';
import AdminFair from './content/modules/Fair/Index';
import AllFairMember from './content/modules/AllFairMember/Index';
import SingleFairApplicationPage from './content/modules/Fair/SingleFair/Index';
import SingleFairMemberPage from './content/modules/AllFairMember/SingleFairMember/Index';
import CreateFairInvoiceByMember from './content/modules/FairInvoice/CreateFairInoice/Index';
import Trainee from './content/modules/Training/Trainee/TraineeList/Index';
import SingleTrainee from './content/modules/Training/Trainee/SingleTrainee/Index';
import BatchList from './content/modules/Training/Batch/BatchList';
import TeacherList from './content/modules/Training/Teacher/teacherList/TeacherList';
import SingleTeacher from './content/modules/Training/Teacher/SingleTeacher/SingleTeacher';
import TraineeApplications from './content/modules/Training/Trainee/TraineeApplications/TraineeApplications';
import CourseList from './content/modules/Training/Course/ShowCourse/CourseList';
import SingleCourse from './content/modules/Training/Course/ShowCourse/SingleCourse';
import AllBatchNotice from './content/modules/Training/Batch/BatchNotice/ShowNotice.tsx/AllNotice';
import TrainingInvoice from './content/modules/Training/TrainingInvoice/TrainingInvoice';
import SingleTrainingInvoice from './content/modules/Training/TrainingInvoice/SingleTrainingInvoice/SingleTrainingInvoice';
import AllEventList from './content/modules/FairEvent/AllEventList/AllEventList';
import SingleEvent from './content/modules/FairEvent/SingleEvent/SingleEvent';
import VisitorList from './content/modules/visitor/allVisitorList/AllVisitorList';
import SingleVisitor from './content/modules/visitor/singleVisitor/SingleVisitor';
import VisitorInvoice from './content/modules/visitorInvoice/Index';
import SingleVisitorInvoicePage from './content/modules/visitorInvoice/SingleVisitor/SingleVisitor';
import ExhibitorInvoice from './content/modules/exhibitor/exhibitorInvoice/ExhibitorInvoice';
import ExhibitorSingleInvoice from './content/modules/exhibitor/exhibitorInvoice/singleInvoice/ExhibitorSingleInvoice';
import EntryFairList from './content/modules/EntryFair/EntryFair';
import StallApplication from './content/modules/Fair/stallApplication/StallApplication';
import SingleStallApplication from './content/modules/Fair/stallApplication/singleStallApplication/SingleStallApplication';
import ExhibitorSingleProduct from './content/modules/AllFairMember/SingleFairMember/SingleProductByExhibitor';
import TrainingInvoiceItem from './content/modules/Training/TrainingInvoiceItem/TrainingInvoiceItem';
import SingleTrainingInvoiceInfo from './content/modules/Training/TrainingInvoiceItem/SingleTrainingInvoiceInfo';
import SingleBatch from './content/modules/Training/Batch/SingleBatch';
import SingleBatchNotice from './content/modules/Training/Batch/BatchNotice/ShowNotice.tsx/SingleNotice';
import AllMaterials from './content/modules/Training/Batch/Material/AllMaterials';
import SingleMaterial from './content/modules/Training/Batch/Material/SingleMaterial';
import AllNoticeOfBatch from './content/modules/Training/Batch/BatchNotice/ShowNotice.tsx/AllNoticeOfBatch';
import AllMaterialsOfSingleBatch from './content/modules/Training/Batch/Material/AllMaterialsOfSingleBatch';
import AllClass from './content/modules/Training/Batch/Class/AllClass';
import SingleClassWithAttendance from './content/modules/Training/Batch/Class/SingleClassWithAttendance';
import ExamList from './content/modules/Training/Batch/Exam/ExamList';
import SingleExam from './content/modules/Training/Batch/Exam/SingleExam/SingleExam';
import StudentOfCourse from './content/modules/Training/Course/ShowCourse/StudentofCourse';
import BatchOfCourse from './content/modules/Training/Course/ShowCourse/BatchOfCourse';
import BatchOfTeacher from './content/modules/Training/Teacher/SingleTeacher/BatchOfTeacher';
import SingleTraineeApplication from './content/modules/Training/Trainee/TraineeApplications/SingleTraineeApplication/SingleTraineeApplication';
import MemberTable from './content/modules/Members/subModules/memberList/allMember/MemberTable';
import ActiveMemberTable from './content/modules/Members/subModules/memberList/activeMember/ActiveMemberTable';
import MeetingTable from './content/modules/Members/Meeting/MeetingTable';
import AdminTable from './content/modules/Admin/AdminTable';
import SingleGuestAndSpeaker from './content/modules/GuestAndSpecker/SingleGuestAndSpeaker';
import AllGuestList from './content/modules/GuestAndSpecker/AllGuestAndSpeaker';
import InvoiceTable from './content/modules/Members/subModules/invoice/allInvoice/InvoiceTable';
import PendingMemberTable from './content/modules/Members/subModules/memberList/pendingMember/PendingMemberTable';
import RejectedMemberTable from './content/modules/Members/subModules/memberList/rejectedMember/RejectedMemberTable';
import RoleTable from './content/modules/Admin/RoleAndPermission/Role/RoleTable';
import PermissionTable from './content/modules/Admin/RoleAndPermission/PermissionList/PermissionTable';
import AuditAndTrail from './content/modules/Admin/AuditAndTrail/AuditAndTrail';
import InvoiceMemberTable from './content/modules/Members/subModules/memberList/invoiceMember/InvoiceMemberTable';
import PermissionRoute from './Reducers/AuthReducer/PermissionRoute';
import BlockedMemberTable from './content/modules/Members/subModules/memberList/blockedMember/BlockedMemberTable';
import SingleRole from './content/modules/Admin/RoleAndPermission/Role/SingleRole/SingleRole';
import EventTable from './content/modules/Members/Event/EventTable';
import NoticeTable from './content/modules/Members/Notice/NoticeTable';
import SingleNotice from './content/modules/Members/Notice/SingleNotice/SingleNotice';
import SingleEventPage from './content/modules/Members/Event/singleEvent/Index';
import RequestCompanyInfo from './content/modules/Members/UpdateRequest/CompanyInformation/RequestCompanyInfo';
import RequestSingleCompanyInfo from './content/modules/Members/UpdateRequest/CompanyInformation/RequestSingleCompanyInfo';
import RequestOwnerInfo from './content/modules/Members/UpdateRequest/OwnerInformation/RequestOwnerInfo';
import RequestSingleOwnerInfo from './content/modules/Members/UpdateRequest/OwnerInformation/RequestSingleOwnerInfo';
import RequestCompanyDocsInfo from './content/modules/Members/UpdateRequest/CompanyDocs/RequestCompanyDocsInfo';
import RequestSingleCompanyDocsInfo from './content/modules/Members/UpdateRequest/CompanyDocs/RequestSingleCompanyDocsInfo';
import RequestTradeLicenseInfo from './content/modules/Members/UpdateRequest/TradeLicenseInfo/RequestTradeLicenseInfo';
import RequestSingleTradeLicenseInfo from './content/modules/Members/UpdateRequest/TradeLicenseInfo/RequestSingleTradeLicenseInfo';
import RequestBusinessLicenseInfo from './content/modules/Members/UpdateRequest/BusinessLicense/RequestBusinessLicenseInfo';
import RequestSingleBusinessLicenseInfo from './content/modules/Members/UpdateRequest/BusinessLicense/RequestSingleBusinessLicenseInfo';
import RequestOtherBusinessLicenseInfo from './content/modules/Members/UpdateRequest/OtherBusinessLicense/RequestOtherBusinessLicenseInfo';
import RequestSingleOtherBusinessLicenseInfo from './content/modules/Members/UpdateRequest/OtherBusinessLicense/RequestSingleOtherBusinessLicenseInfo';
import RequestCivilAviationInfo from './content/modules/Members/UpdateRequest/CivilAviationInformation/RequestCivilAviationInfo';
import RequestSingleCivilAviationInfo from './content/modules/Members/UpdateRequest/CivilAviationInformation/RequestSingleCivilAviationInfo';
import RequestProposerInfo from './content/modules/Members/UpdateRequest/ProposerInformation/RequestProposerInfo';
import RequestSingleProposerInfo from './content/modules/Members/UpdateRequest/ProposerInformation/RequestSingleProposerInfo';
import IdCard from './content/modules/Members/Applications/IDCard/IdCard';
import SingleIdCard from './content/modules/Members/Applications/IDCard/SingleIdCard';
import ReceiveReport from './content/modules/Report/ReceiveReport/ReceiveReport';
import DueReport from './content/modules/Report/DueReport/DueReport';
import SubscriptionReport from './content/modules/Report/SubscriptionReport/SubscriptionReport';
import Address from './content/modules/address/district/Address';
import RenewalApplication from './content/modules/Members/Applications/RenewalApplication/RenewalApplication';
import AddressApplication from './content/modules/Members/Applications/AddressApplication/AddressApplication';
import OwnerShipChangeApplication from './content/modules/Members/Applications/OwnershipChangeApplication/OwnershipChangeApplication';
import DuplicateCertificate from './content/modules/Members/Applications/DuplicateCertificate/DuplicateCertificate';
import SingleRenewalApplication from './content/modules/Members/Applications/RenewalApplication/SingleRenewalApplication';
import SingleAddressApplication from './content/modules/Members/Applications/AddressApplication/SingleAddressApplication';
import SingleOwnershipChangeApplication from './content/modules/Members/Applications/OwnershipChangeApplication/SingleOwnershipChangeApplication';
import SingleDuplicateCertificateApplication from './content/modules/Members/Applications/DuplicateCertificate/SingleDuplicateCertificateApplication';
import AllBooks from './content/modules/Training/ServiceRequest/Books/AllBooks';
import SingleBook from './content/modules/Training/ServiceRequest/Books/SingleBook';
// import AllDuplicateList from "./content/modules/Training/ServiceRequest/TraineeDuplicateId/AllDuplicateListId";
import SingleTraineeDuplicateId from './content/modules/Training/ServiceRequest/TraineeDuplicateId/SingleTraineeDuplicateId';
import AllDuplicateListId from './content/modules/Training/ServiceRequest/TraineeDuplicateId/AllDuplicateListId';
import TraineeDuplicateCertificateList from './content/modules/Training/ServiceRequest/TraineeDuplicateCertificate/TraineeDuplicateCertificateList';
import SingleTraineeDuplicateCertificate from './content/modules/Training/ServiceRequest/TraineeDuplicateCertificate/SingleTraineeDuplicateCertificate';
import TrainingReport from './content/modules/Training/TrainingReport/TrainingReport';
import SingleTraineeReport from './content/modules/Training/TrainingReport/SingleTraineeReport';
import AgencyInfoUpdateApplication from './content/modules/Members/Applications/AgencyInfoUpdateApplication/AgencyInfoUpdateApplication';
import SingleAgencyInfoUpdateApplication from './content/modules/Members/Applications/AgencyInfoUpdateApplication/SingleAgencyInfoUpdateApplication';
import AgencyWiseReport from './content/modules/Training/TrainingReport/AgencyWise/AgencyWiseReport';
import BatchWiseReport from './content/modules/Training/TrainingReport/BatchWise/BatchWiseReport';
import VoucherEntry from './content/modules/Accounts/Vouchers/pages/VoucherEntry';
import TrialBalance from './content/modules/Accounts/Reports/Pages/TrialBalance';
import Ledger from './content/modules/Accounts/Reports/Pages/Ledger';
import CashBook from './content/modules/Accounts/Reports/Pages/CashBook';
import BankBook from './content/modules/Accounts/Reports/Pages/BankBook';
import IncomeStatement from './content/modules/Accounts/Reports/Pages/IncomeStatement';
import SubsidiaryLedger from './content/modules/Accounts/Reports/Pages/SubsidiaryLedger';
import SearchVoucher from './content/modules/Accounts/Vouchers/pages/SearchVoucher';
import ReceivePayment from './content/modules/Accounts/Reports/Pages/ReceivePayment';
import BalanceSheet from './content/modules/Accounts/Reports/Pages/BalanceSheet';
import Bank from './content/modules/Accounts/Bank/pages/Bank';
import ChequeRegisterEntry from './content/modules/Accounts/Bank/pages/ChequeRegisterEntry';
import ApplicationChecker from './content/modules/Members/subModules/memberList/applicationChecker/ApplicationChecker';
import SingleMoneyReceipt from './content/modules/Members/subModules/moneyReceipt/pages/SingleMoneyReceipt';
import MoneyReceiptTable from './content/modules/Members/subModules/moneyReceipt/pages/MoneyReceiptTable';
import UpdateSingleTraineeApplication from './content/modules/Training/Trainee/TraineeApplications/SingleTraineeApplication/update/UpdateSingleTraineeApplication';
import CompletedInspection from './content/modules/Members/subModules/memberList/CompletedInspection';
import MemberInProgress from './content/modules/Members/subModules/memberList/MemberInProgress';
import IdCardList from './content/modules/Training/Batch/IdCardList';
import ExpenseList from './content/modules/Members/MemberAccount/Expense/page/ExpenseList';
import ChequeManagementList from './content/modules/Members/MemberAccount/ChequeManagement/page/ChequeManagementList';
import AccountList from './content/modules/Members/MemberAccount/Account/page/AccountList';
import ExpenseHeadList from './content/modules/Members/MemberAccount/Expense/page/ExpenseHeadList';
import TraineeAccountList from './content/modules/Training/TraineeAccount/Account/page/TraineeAccountList';
import TraineeChequeManagementList from './content/modules/Training/TraineeAccount/ChequeManagement/page/TraineeChequeManagementList';
import TraineeExpenseHeadList from './content/modules/Training/TraineeAccount/Expense/page/TraineeExpenseHeadList';
import TraineeExpenseList from './content/modules/Training/TraineeAccount/Expense/page/TraineeExpenseList';
import JournalReport from './content/modules/Report/Journal/JournalReport';
import TraineeJournalReport from './content/modules/Training/TrainingReport/Journal/TraineeJournalReport';
import LedgerReport from './content/modules/Report/Ledger/LedgerReport';
import TrialBalanceReport from './content/modules/Report/TrialBalance/TrialBalanceReport';
import IncomeStatementReport from './content/modules/Report/IncomeStatement/IncomeStatementReport';
import BalanceSheetReport from './content/modules/Report/BalanceSheet/BalanceSheetReport';
import TraineeTrialBalanceReport from './content/modules/Training/TrainingReport/TrialBalance/TraineeTrialBalanceReport';
import TraineeIncomeStatementReport from './content/modules/Training/TrainingReport/IncomeStatement/TraineeIncomeStatementReport';
import TraineeBalanceSheetReport from './content/modules/Training/TrainingReport/BalanceSheet/TraineeBalanceSheetReport';
import TraineeLedgerReport from './content/modules/Training/TrainingReport/Ledger/TraineeLedgerReport';
import TraineeMoneyReceiptTable from './content/modules/Training/TraineeAccount/TraineeMoneyReceipt/page/TraineeMoneyReceipt';
import TraineeSingleMoneyReceipt from './content/modules/Training/TraineeAccount/TraineeMoneyReceipt/page/TraineeSingleMoneyReceipt';
import ChartOfAccount from './content/modules/Members/MemberAccount/Account/page/ChartOfAccount';
import TraineeChartOfAccount from './content/modules/Training/TraineeAccount/Account/page/TraineeChartOfAccount';
import SingleTraineeLedgerReport from './content/modules/Training/TrainingReport/SingleTraineeLedger/SingleTraineeLedgerReport';
import SingleMemberLedgerReport from './content/modules/Report/SingleMemberLedger/SingleMemberLedgerReport';
import FeeWiseMemberReport from './content/modules/Report/FeeWise/FeeWiseMemberReport';
import ViewExpense from './content/modules/Members/MemberAccount/Expense/components/ViewExpense';
import ViewTraineeExpense from './content/modules/Training/TraineeAccount/Expense/components/ViewTraineeExpense';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const SingleInvoicePage = Loader(
  lazy(
    () =>
      import(
        './content/modules/Members/subModules/invoice/singleInvoice/SingleInvoicePage'
      )
  )
);

// login
const Login = Loader(lazy(() => import('./content/pages/Login/Login')));

// Dashboards
const DashboardOverview = Loader(
  lazy(() => import('src/content/modules/dashboard'))
);

// Applications
const Messenger = Loader(lazy(() => import('src/content/modules/Messenger')));
const UserProfile = Loader(
  lazy(() => import('src/content/modules/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/modules/Users/settings'))
);

// Status loader
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: 'dashboard',
        element: <DashboardOverview />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to='404' replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
    ],
  },

  {
    path: 'fairinvoice',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <FairInvoice />,
      },
      {
        path: 'list/:fairMemberId',
        element: <FairInvoiceByFairMember />,
      },
      {
        path: 'create',
        element: <CreateFairInvoiceByMember />,
      },
    ],
  },

  // This is fair module routing file
  {
    path: 'fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <PermissionRoute component={<AdminFair />} route='fair' />,
      },
      {
        path: 'list/:singleFairId',
        element: (
          <PermissionRoute
            component={<SingleFairApplicationPage />}
            route='fair'
          />
        ),
      },
      {
        path: 'stall-list',
        element: <PermissionRoute component={<FairStallList />} route='fair' />,
      },
      {
        path: 'stall-application',
        element: (
          <PermissionRoute component={<StallApplication />} route='fair' />
        ),
      },
      {
        path: 'stall-application/:stallApplicationId',
        element: (
          <PermissionRoute
            component={<SingleStallApplication />}
            route='fair'
          />
        ),
      },
    ],
  },

  // Exhibitor Module
  {
    path: 'exhibitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/exhibitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<AllFairMember />} route='exhibitor' />
        ),
      },
      {
        path: 'list/:singleMemberFairId',
        element: (
          <PermissionRoute
            component={<SingleFairMemberPage />}
            route='exhibitor'
          />
        ),
      },
      {
        path: 'list/:singleMemberFairId/product/:ExhibitorSingleProduct',
        element: (
          <PermissionRoute
            component={<ExhibitorSingleProduct />}
            route='exhibitor'
          />
        ),
      },
      {
        path: 'invoice-list',
        element: (
          <PermissionRoute component={<ExhibitorInvoice />} route='exhibitor' />
        ),
      },
      {
        path: 'invoice-list/:singleExhibitorInvoiceId',
        element: (
          <PermissionRoute
            component={<ExhibitorSingleInvoice />}
            route='exhibitor'
          />
        ),
      },
    ],
  },

  {
    path: 'visitor',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/visitor',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<VisitorList />} route='visitor' />
        ),
      },
      {
        path: 'list/:singleVisitorId',
        element: (
          <PermissionRoute component={<SingleVisitor />} route='visitor' />
        ),
      },
      {
        path: 'visitor-invoice',
        element: (
          <PermissionRoute component={<VisitorInvoice />} route='visitor' />
        ),
      },
      {
        path: 'visitor-invoice/:visitorInvoiceId',
        element: (
          <PermissionRoute
            component={<SingleVisitorInvoicePage />}
            route='visitor'
          />
        ),
      },
    ],
  },

  {
    path: 'fair-event',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/fair-event',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'all-event-list',
        element: <PermissionRoute component={<AllEventList />} route='event' />,
      },
      {
        path: 'all-event-list/:eventId',
        element: <PermissionRoute component={<SingleEvent />} route='event' />,
      },
    ],
  },

  // Guest and Speaker
  {
    path: 'guest-speaker',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/guest-speaker',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'guest-list',
        element: (
          <PermissionRoute component={<AllGuestList />} route='guest-speaker' />
        ),
      },
      {
        path: 'guest-list/details/:id',
        element: (
          <PermissionRoute
            component={<SingleGuestAndSpeaker />}
            route='guest-speaker'
          />
        ),
      },
    ],
  },
  // This is entry fair module routing file
  {
    path: 'entry-fair',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/entry-fair',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<EntryFairList />} route='entry-fair' />
        ),
      },
    ],
  },
  {
    path: 'support',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='conversations' replace />,
      },
      {
        path: 'conversations',
        element: (
          <PermissionRoute component={<Messenger />} route='conversations' />
        ),
      },
    ],
  },

  {
    path: 'invoices',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='transactions' replace />,
      },
      {
        path: 'transactions',
        element: (
          <PermissionRoute
            component={<InvoiceTable />}
            route='member-invoice'
          />
        ),
      },
      {
        path: 'transactions/:id',
        element: (
          <PermissionRoute
            component={<SingleInvoicePage />}
            route='member-invoice'
          />
        ),
      },
    ],
  },
  {
    path: 'paymentable-item',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<PaymentableItemList />}
            route='member-invoice'
          />
        ),
      },
    ],
  },
  {
    path: 'money-receipt',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<MoneyReceiptTable />}
            route='member-money-receipt'
          />
        ),
      },
      {
        path: 'list/:id',
        element: (
          <PermissionRoute
            component={<SingleMoneyReceipt />}
            route='member-money-receipt'
          />
        ),
      },
    ],
  },
  {
    path: 'account',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<AccountList />} route='account' />
        ),
      },
      {
        path: 'chart-of-account',
        element: (
          <PermissionRoute component={<ChartOfAccount />} route='account' />
        ),
      },
    ],
  },
  // {
  //   path: "cheque-management",
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="list" replace />,
  //     },
  //     {
  //       path: "list",
  //       element: <ChequeManagementList />,
  //     },
  //   ],
  // },
  {
    path: 'expense',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'head/list',
        element: (
          <PermissionRoute
            component={<ExpenseHeadList />}
            route='member-expense'
          />
        ),
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<ExpenseList />} route='member-expense' />
        ),
      },
      {
        path: 'list/:id',
        element: (
          <PermissionRoute component={<ViewExpense />} route='member-expense' />
        ),
      },
    ],
  },

  {
    path: 'training-invoice-item',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: <TrainingInvoiceItem />,
      },
      {
        path: 'list/details/:traineeInvoiceItemId',
        element: <SingleTrainingInvoiceInfo />,
      },
    ],
  },
  {
    path: 'member-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <MemberTable />,
      },
      {
        path: 'list',
        element: <MemberTable />,
      },
      // {
      //   path: 'fee-wise',
      //   element: <InvoiceMemberTable />,
      // },
      {
        path: 'active',
        element: <ActiveMemberTable />,
      },
      {
        path: 'pending',
        element: <PendingMemberTable />,
      },
      {
        path: 'complete-inspection',
        element: <CompletedInspection />,
      },
      {
        path: 'member-inprogress',
        element: <MemberInProgress />,
      },
      {
        path: 'rejected',
        element: <RejectedMemberTable />,
      },
      {
        path: 'blocked',
        element: <BlockedMemberTable />,
      },
      {
        path: 'checker-list',
        element: <ApplicationChecker />,
      },
      {
        path: ':memberId',
        element: <CompanyInfoDetails />,
      },

      {
        path: 'meeting',
        element: <MeetingTable />,
      },
      {
        path: 'meeting/:meetingId',
        element: <SingleMeetingPage />,
      },
    ],
  },
  {
    path: 'update-request',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PermissionRoute
            component={<RequestCompanyInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'company-information',
        element: (
          <PermissionRoute
            component={<RequestCompanyInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'company-information/:companyID',
        element: <RequestSingleCompanyInfo />,
      },
      {
        path: 'owner-info',
        element: (
          <PermissionRoute
            component={<RequestOwnerInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'owner-info/:ownerId',
        element: <RequestSingleOwnerInfo />,
      },
      {
        path: 'company-docs',
        element: (
          <PermissionRoute
            component={<RequestCompanyDocsInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'company-docs/:id',
        element: <RequestSingleCompanyDocsInfo />,
      },
      {
        path: 'trade-license',
        element: (
          <PermissionRoute
            component={<RequestTradeLicenseInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'trade-license/:id',
        element: <RequestSingleTradeLicenseInfo />,
      },
      {
        path: 'business-license',
        element: (
          <PermissionRoute
            component={<RequestBusinessLicenseInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'business-license/:id',
        element: <RequestSingleBusinessLicenseInfo />,
      },
      {
        path: 'other-business-license',
        element: (
          <PermissionRoute
            component={<RequestOtherBusinessLicenseInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'other-business-license/:id',
        element: <RequestSingleOtherBusinessLicenseInfo />,
      },
      {
        path: 'civil-aviation',
        element: (
          <PermissionRoute
            component={<RequestCivilAviationInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'civil-aviation/:id',
        element: <RequestSingleCivilAviationInfo />,
      },
      {
        path: 'proposer-info',
        element: (
          <PermissionRoute
            component={<RequestProposerInfo />}
            route='member-list'
          />
        ),
      },
      {
        path: 'proposer-info/:id',
        element: <RequestSingleProposerInfo />,
      },
    ],
  },

  {
    path: 'member-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PermissionRoute component={<MemberTable />} route='member-list' />
        ),
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<MemberTable />} route='member-list' />
        ),
      },
      {
        path: 'fee-wise',
        element: (
          <PermissionRoute
            component={<InvoiceMemberTable />}
            route='member-list'
          />
        ),
      },
      {
        path: 'active',
        element: (
          <PermissionRoute
            component={<ActiveMemberTable />}
            route='member-list'
          />
        ),
      },
      {
        path: 'application-checker',
        element: (
          <PermissionRoute
            component={<ApplicationChecker />}
            route='member-list'
          />
        ),
      },
      {
        path: 'pending',
        element: (
          <PermissionRoute
            component={<PendingMemberTable />}
            route='member-list'
          />
        ),
      },
      {
        path: 'rejected',
        element: (
          <PermissionRoute
            component={<RejectedMemberTable />}
            route='member-list'
          />
        ),
      },
      {
        path: ':memberId',
        element: (
          <PermissionRoute
            component={<CompanyInfoDetails />}
            route='member-list'
          />
        ),
      },
      {
        path: 'meeting',
        element: (
          <PermissionRoute component={<MeetingTable />} route='others' />
        ),
      },
      {
        path: 'meeting/:meetingId',
        element: (
          <PermissionRoute component={<SingleMeetingPage />} route='others' />
        ),
      },
      {
        path: 'event',
        element: <PermissionRoute component={<EventTable />} route='others' />,
      },
      {
        path: 'event/:eventId',
        element: (
          <PermissionRoute component={<SingleEventPage />} route='others' />
        ),
      },
      {
        path: 'notice',
        element: <PermissionRoute component={<NoticeTable />} route='others' />,
      },
      {
        path: 'notice/:noticeId',
        element: (
          <PermissionRoute component={<SingleNotice />} route='others' />
        ),
      },
    ],
  },
  {
    path: 'report',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PermissionRoute
            component={<FeeWiseMemberReport />}
            route='trainee'
          />
        ),
      },
      {
        path: 'fee-wise',
        element: <FeeWiseMemberReport />,
      },
      {
        path: 'receive',
        element: <ReceiveReport />,
      },
      {
        path: 'due',
        element: <DueReport />,
      },
      {
        path: 'subscription',
        element: <SubscriptionReport />,
      },
      {
        path: 'journal',
        element: <JournalReport />,
      },
      {
        path: 'ledger',
        element: <LedgerReport />,
      },
      {
        path: 'member-ledger',
        element: <SingleMemberLedgerReport />,
      },
      {
        path: 'trail-balance',
        element: <TrialBalanceReport />,
      },
      {
        path: 'income-statement',
        element: <IncomeStatementReport />,
      },
      {
        path: 'balance-sheet',
        element: <BalanceSheetReport />,
      },
    ],
  },
  {
    path: 'update-applications',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: 'id-card',
        element: (
          <PermissionRoute component={<IdCard />} route='update-applications' />
        ),
      },
      {
        path: 'id-card/:cardID',
        element: (
          <PermissionRoute
            component={<SingleIdCard />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'renewal-application',
        element: (
          <PermissionRoute
            component={<RenewalApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'renewal-application/:id',
        element: (
          <PermissionRoute
            component={<SingleRenewalApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'address-change-application',
        element: (
          <PermissionRoute
            component={<AddressApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'address-change-application/:id',
        element: (
          <PermissionRoute
            component={<SingleAddressApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'ownership-change-application',
        element: (
          <PermissionRoute
            component={<OwnerShipChangeApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'ownership-change-application/:id',
        element: (
          <PermissionRoute
            component={<SingleOwnershipChangeApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'duplicate-certificate',
        element: (
          <PermissionRoute
            component={<DuplicateCertificate />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'duplicate-certificate/:id',
        element: (
          <PermissionRoute
            component={<SingleDuplicateCertificateApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'agency-info-update',
        element: (
          <PermissionRoute
            component={<AgencyInfoUpdateApplication />}
            route='update-applications'
          />
        ),
      },
      {
        path: 'agency-info-update/:id',
        element: (
          <PermissionRoute
            component={<SingleAgencyInfoUpdateApplication />}
            route='update-applications'
          />
        ),
      },
    ],
  },
  {
    path: 'training-trainee',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <PermissionRoute component={<Trainee />} route='trainee' />,
      },
      {
        path: 'list',
        element: <PermissionRoute component={<Trainee />} route='trainee' />,
      },
      {
        path: 'details/:id',
        element: (
          <PermissionRoute component={<SingleTrainee />} route='trainee' />
        ),
      },
      {
        path: 'applications',
        element: (
          <PermissionRoute
            component={<TraineeApplications />}
            route='trainee'
          />
        ),
      },
      {
        path: 'application/details/:singleTraineeId/edit',
        element: (
          <PermissionRoute
            component={<UpdateSingleTraineeApplication />}
            route='trainee'
          />
        ),
      },
      {
        path: 'application/details/:singleTraineeId',
        element: (
          <PermissionRoute
            component={<SingleTraineeApplication />}
            route='trainee'
          />
        ),
        children: [
          {
            path: 'list',
            element: (
              <PermissionRoute component={<Trainee />} route='trainee' />
            ),
          },
          {
            path: 'applications',
            element: (
              <PermissionRoute
                component={<TraineeApplications />}
                route='trainee'
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'training-batch',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <PermissionRoute component={<BatchList />} route='batch' />,
      },
      {
        path: 'list',
        element: <PermissionRoute component={<BatchList />} route='batch' />,
      },
      {
        path: 'details/:id',
        element: <PermissionRoute component={<SingleBatch />} route='batch' />,
      },
      {
        path: ':id/id-card',
        element: <PermissionRoute component={<IdCardList />} route='batch' />,
      },

      {
        path: 'exam-list/:batchId',
        element: <PermissionRoute component={<ExamList />} route='batch' />,
      },
      {
        path: 'exam-list/details/:singleExamId',
        element: <PermissionRoute component={<SingleExam />} route='batch' />,
      },
      {
        path: 'notice-list',
        element: (
          <PermissionRoute component={<AllBatchNotice />} route='batch' />
        ),
      },
      {
        path: 'notice/:id',
        element: (
          <PermissionRoute component={<SingleBatchNotice />} route='batch' />
        ),
      },
      {
        path: 'notice/batch/:id',
        element: (
          <PermissionRoute component={<AllNoticeOfBatch />} route='batch' />
        ),
      },
      {
        path: 'material-list',
        element: <PermissionRoute component={<AllMaterials />} route='batch' />,
      },
      {
        path: 'material/:id',
        element: (
          <PermissionRoute component={<SingleMaterial />} route='batch' />
        ),
      },
      {
        path: 'material/batch/:id',
        element: (
          <PermissionRoute
            component={<AllMaterialsOfSingleBatch />}
            route='batch'
          />
        ),
      },
      {
        path: 'class-list/:id',
        element: <PermissionRoute component={<AllClass />} route='batch' />,
      },
      {
        path: 'class/:id',
        element: (
          <PermissionRoute
            component={<SingleClassWithAttendance />}
            route='batch'
          />
        ),
      },
    ],
  },
  {
    path: 'training-course',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <h2>Training course list</h2>,
      },
      {
        path: 'list',
        element: <PermissionRoute component={<CourseList />} route='course' />,
      },
      {
        path: 'details/:id',
        element: (
          <PermissionRoute component={<SingleCourse />} route='course' />
        ),
      },
      {
        path: 'student-list/:courseId',
        element: (
          <PermissionRoute component={<StudentOfCourse />} route='course' />
        ),
      },
      {
        path: 'batch-list/:courseId',
        element: (
          <PermissionRoute component={<BatchOfCourse />} route='course' />
        ),
      },
    ],
  },
  {
    path: 'training-teacher',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <h2>Teacher list</h2>,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<TeacherList />} route='teacher' />
        ),
      },
      {
        path: 'details/:id',
        element: (
          <PermissionRoute component={<SingleTeacher />} route='teacher' />
        ),
      },
      {
        path: 'batch-list/:teacherId',
        element: (
          <PermissionRoute component={<BatchOfTeacher />} route='teacher' />
        ),
      },
    ],
  },
  {
    path: 'training-invoice',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '/training-invoice',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TrainingInvoice />}
            route='training-invoice'
          />
        ),
      },
      {
        path: 'details/:singleTraineeInvoice',
        element: (
          <PermissionRoute
            component={<SingleTrainingInvoice />}
            route='training-invoice'
          />
        ),
      },
    ],
  },
  {
    path: 'training-invoice-item',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TrainingInvoiceItem />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'list/details/:traineeInvoiceItemId',
        element: (
          <PermissionRoute
            component={<SingleTrainingInvoiceInfo />}
            route='training-invoice-item'
          />
        ),
      },
    ],
  },
  {
    path: 'trainee-money-receipt',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TraineeMoneyReceiptTable />}
            route='training-money-receipt'
          />
        ),
      },
      {
        path: 'details/:id',
        element: (
          <PermissionRoute
            component={<TraineeSingleMoneyReceipt />}
            route='training-money-receipt'
          />
        ),
      },
    ],
  },
  {
    path: 'trainee-account',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TraineeAccountList />}
            route='training-account'
          />
        ),
      },
      {
        path: 'chart-of-account',
        element: (
          <PermissionRoute
            component={<TraineeChartOfAccount />}
            route='training-account'
          />
        ),
      },
    ],
  },
  // {
  //   path: "trainee-cheque-management",
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="list" replace />,
  //     },
  //     {
  //       path: "list",
  //       element: <TraineeChequeManagementList />,
  //     },
  //   ],
  // },
  {
    path: 'trainee-expense-head',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TraineeExpenseHeadList />}
            route='training-expense'
          />
        ),
      },
    ],
  },
  {
    path: 'trainee-expense',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TraineeExpenseList />}
            route='training-expense'
          />
        ),
      },

      {
        path: 'list/:id',
        element: (
          <PermissionRoute
            component={<ViewTraineeExpense />}
            route='training-expense'
          />
        ),
      },
    ],
  },
  {
    path: 'service-request',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<AllBooks />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'list/details/:traineeInvoiceItemId',
        element: (
          <PermissionRoute
            component={<SingleTrainingInvoiceInfo />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'books',
        element: (
          <PermissionRoute
            component={<AllBooks />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'books/:bookID',
        element: (
          <PermissionRoute
            component={<SingleBook />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'trainee-duplicate-id',
        element: (
          <PermissionRoute
            component={<AllDuplicateListId />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'trainee-duplicate-id/:id',
        element: (
          <PermissionRoute
            component={<SingleTraineeDuplicateId />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'trainee-duplicate-certificate',
        element: (
          <PermissionRoute
            component={<TraineeDuplicateCertificateList />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'trainee-duplicate-certificate/:id',
        element: (
          <PermissionRoute
            component={<SingleTraineeDuplicateCertificate />}
            route='training-invoice-item'
          />
        ),
      },
    ],
  },
  {
    path: 'training-report',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />,
      },
      {
        path: 'list',
        element: (
          <PermissionRoute
            component={<TrainingReport />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'list/:id',
        element: (
          <PermissionRoute
            component={<SingleTraineeReport />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'agency-wise',
        element: (
          <PermissionRoute
            component={<AgencyWiseReport />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'batch-wise',
        element: (
          <PermissionRoute
            component={<BatchWiseReport />}
            route='training-invoice-item'
          />
        ),
      },
      {
        path: 'journal',
        element: <TraineeJournalReport />,
      },
      {
        path: 'ledger',
        element: <TraineeLedgerReport />,
      },
      {
        path: 'trainee-ledger',
        element: <SingleTraineeLedgerReport />,
      },
      {
        path: 'trial-balance',
        element: <TraineeTrialBalanceReport />,
      },
      {
        path: 'income-statement',
        element: <TraineeIncomeStatementReport />,
      },
      {
        path: 'balance-sheet',
        element: <TraineeBalanceSheetReport />,
      },
    ],
  },

  //Admin module route
  {
    path: 'admin-list',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: (
          <PermissionRoute component={<AdminTable />} route='all-admin' />
        ),
      },
      {
        path: 'list',
        element: (
          <PermissionRoute component={<AdminTable />} route='all-admin' />
        ),
      },
      {
        path: 'single-admin/:id',
        element: (
          <PermissionRoute component={<SingleAdmin />} route='all-admin' />
        ),
      },
      {
        path: 'audit-trail',
        element: (
          <PermissionRoute component={<AuditAndTrail />} route='audit-trail' />
        ),
      },
      {
        path: 'role',
        element: (
          <PermissionRoute component={<RoleTable />} route='role-permission' />
        ),
      },
      {
        path: 'role/:id',
        element: (
          <PermissionRoute component={<SingleRole />} route='role-permission' />
        ),
      },
      {
        path: 'permission',
        element: (
          <PermissionRoute
            component={<PermissionTable />}
            route='role-permission'
          />
        ),
      },
    ],
  },

  // {
  //   path: "sms",
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: "",
  //       element: <PermissionRoute component={<SMS />} route="sms" />,
  //     },
  //   ],
  // },
  // {
  //   path: "email",
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: "",
  //       element: <PermissionRoute component={<EmailPanel />} route="sms" />,
  //     },
  //   ],
  // },

  {
    path: 'profile',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='details' replace />,
      },
      {
        path: 'details',
        element: (
          <PermissionRoute component={<UserProfile />} route='user-profile' />
        ),
      },
      {
        path: 'settings',
        element: (
          <PermissionRoute component={<UserSettings />} route='user-profile' />
        ),
      },
    ],
  },
  {
    path: 'address',
    element: <PrivateRoute component={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to='district' replace />,
      },
      {
        path: 'district',
        element: (
          <PermissionRoute component={<Address />} route='user-profile' />
        ),
      },
      {
        path: 'settings',
        element: (
          <PermissionRoute component={<UserSettings />} route='user-profile' />
        ),
      },
    ],
  },

  // accounts routes
  // {
  //   path: "accounts",
  //   element: <PrivateRoute component={<SidebarLayout />} />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="voucher-entry" replace />,
  //     },
  //     {
  //       path: "voucher-entry",
  //       element: (
  //         <PermissionRoute
  //           component={<VoucherEntry />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "search-voucher",
  //       element: (
  //         <PermissionRoute
  //           component={<SearchVoucher />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "trial-balance",
  //       element: (
  //         <PermissionRoute
  //           component={<TrialBalance />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "ledger",
  //       element: (
  //         <PermissionRoute component={<Ledger />} route="pure-accounting" />
  //       ),
  //     },
  //     {
  //       path: "cash-book",
  //       element: (
  //         <PermissionRoute component={<CashBook />} route="pure-accounting" />
  //       ),
  //     },
  //     {
  //       path: "bank-book",
  //       element: (
  //         <PermissionRoute component={<BankBook />} route="pure-accounting" />
  //       ),
  //     },
  //     {
  //       path: "subsidiary-ledger",
  //       element: (
  //         <PermissionRoute
  //           component={<SubsidiaryLedger />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "receive-payment",
  //       element: (
  //         <PermissionRoute
  //           component={<ReceivePayment />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "income-statement",
  //       element: (
  //         <PermissionRoute
  //           component={<IncomeStatement />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     // {
  //     //   path: "Fee-wise",
  //     //   element: (
  //     //     <PermissionRoute
  //     //       component={<IncomeStatement />}
  //     //       route="pure-accounting"
  //     //     />
  //     //   ),
  //     // },
  //     {
  //       path: "Balance-sheet",
  //       element: (
  //         <PermissionRoute
  //           component={<BalanceSheet />}
  //           route="pure-accounting"
  //         />
  //       ),
  //     },
  //     {
  //       path: "bank",
  //       children: [
  //         {
  //           path: "",
  //           element: <Navigate to="list" replace />,
  //         },
  //         {
  //           path: "list",
  //           element: (
  //             <PermissionRoute component={<Bank />} route="pure-accounting" />
  //           ),
  //         },
  //         {
  //           path: "cheque-register-entry",
  //           element: (
  //             <PermissionRoute
  //               component={<ChequeRegisterEntry />}
  //               route="pure-accounting"
  //             />
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    path: 'login',
    element: <Login />,
  },

  {
    path: 'forget-password',
    element: <ForgetPassword />,
  },

  {
    path: 'forget-password/otp',
    element: <OtpSend />,
  },

  {
    path: 'change-password/*',
    element: <ChangePassword />,
  },

  {
    path: '*',
    element: <Status404 />,
  },
]);
