import { Visibility } from '@mui/icons-material';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import moment from 'moment';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { HttpClient } from 'src/Helpers/http/http';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import DeleteConfirmation from 'src/Utils/DeleteConfirmation';
import { IExpenseType } from '../types/expenseTypes';
import UpdateExpense from './UpdateExpense';
import { Link } from 'react-router-dom';

type IProps = {
  expenseData: IExpenseType;
  allExpense: IExpenseType[];
  setAllExpense: any;
  setRefresh: any;
};
const ExpenseTableRow = ({
  expenseData,
  allExpense,
  setAllExpense,
  setRefresh,
}: IProps) => {
  const {
    expense_no,
    amount,
    expense_date,
    method,
    account_name,
    id,
    voucher_no,
  } = expenseData || {};
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/atab/expense/${id}?CompanyProfileId=1`;
        const data = await HttpClient.delete(uri);
        if (data) {
          const filterData = allExpense.filter((item) => item.id !== id);
          setAllExpense(filterData);
          Toaster().fire({
            icon: 'success',
            title: 'Deleted successfully!',
          });

          setIsLoading(false);
          setOpen2(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };
  return (
    <TableRow hover>
      <TableCell>{expense_no}</TableCell>
      <TableCell>{voucher_no}</TableCell>
      <TableCell>{moment(expense_date).format('DD-MM-YYYY')}</TableCell>
      <TableCell>{method}</TableCell>
      <TableCell>{account_name}</TableCell>
      <TableCell>{amount}</TableCell>
      <TableCell>
        <Tooltip title='View' arrow>
          <Link to={`${id}`}>
            <IconButton
              sx={{
                '&:hover': {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color='inherit'
              size='small'
            >
              <Visibility fontSize='small' />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title='Delete expense' arrow>
          <IconButton
            onClick={() => setOpen2(true)}
            sx={{
              '&:hover': { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color='inherit'
            size='small'
          >
            <DeleteTwoToneIcon fontSize='small' />
          </IconButton>
        </Tooltip>

        <DeleteConfirmation
          open={open2}
          handleClose={() => setOpen2(false)}
          text='Want to delete this expense?'
          handleDeleteFun={() => handleDelete(id)}
          loading={isLoading}
        />
      </TableCell>
      <ModalsWrapper
        modalData={{ title: 'Update Expense Head' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <UpdateExpense
          setRefresh={setRefresh}
          handelClose={() => setOpen(false)}
          id={id}
          singleData={expenseData}
        />
      </ModalsWrapper>
    </TableRow>
  );
};

export default ExpenseTableRow;
