import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { url } from 'src/Helpers/utils/Constant';
import { HttpClient } from 'src/Helpers/http/http';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ITraineeExpenseHeadType } from '../types/traineeExpenseTypes';
import { ITraineeAccountType } from '../../Account/types/TraineeAccountTypes';
import moment from 'moment';

const CreateTraineeExpense = ({ setRefresh, handelClose }: any) => {
  const { register, handleSubmit, control, watch } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });
  if (fields.length === 0) {
    append({ id: '', amount: '' });
  }
  const [expenseHead, setExpenseHead] = useState<ITraineeExpenseHeadType[]>([]);
  const [accountData, setAccountData] = useState<ITraineeAccountType[]>([]);
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch('method');

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/expense-head?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setExpenseHead(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setExpenseHead([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setAccountData([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { CompanyProfileId: 2, ...inputs };
    try {
      const res: any = await HttpClient.post(
        `${url}/api/atab/expense`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: 'success',
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{ padding: '0 15px' }}
      >
        <Grid container>
          <Grid item xs={12}>
            {fields?.map((item, index) => (
              <Grid
                container
                spacing={1}
                key={item.id}
                alignItems='center'
                sx={{
                  marginBottom: '10px',
                }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel id={`expense-head-label-${index}`}>
                      Expense Head
                    </InputLabel>
                    <Select
                      labelId={`expense-head-label-${index}`}
                      id={`expense-head-select-${index}`}
                      {...register(`items.${index}.id`)}
                      label='Expense Head'
                    >
                      {expenseHead?.map((sExpense) => (
                        <MenuItem key={sExpense.id} value={sExpense.id}>
                          {sExpense.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    required
                    label='Amount'
                    type='number'
                    variant='outlined'
                    {...register(`items.${index}.amount`)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2} display={'flex'} columnGap={1}>
                  {fields.length > 1 && (
                    <Grid item xs='auto'>
                      <IconButton
                        onClick={() => remove(index)}
                        color='error'
                        sx={{ backgroundColor: 'rgba(255, 25, 67, 0.1)' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  {index === 0 && (
                    <Grid item xs='auto'>
                      <IconButton
                        onClick={() => append({ id: '', amount: '' })}
                        color='primary'
                        sx={{ backgroundColor: 'rgba(12, 77, 162, 0.1)' }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* Additional Form Fields */}
          <Grid item xs={12} sx={{ marginTop: 5 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id='expense_date'
                  label='Expense Date'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  {...register('expense_date')}
                  defaultValue={moment().format('YYYY-MM-DD')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  required
                  id='method'
                  label='Payment Method'
                  variant='outlined'
                  {...register('method')}
                >
                  {['Mobile Banking', 'Cash', 'Bank', 'Cheque'].map(
                    (method) => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel id='account-select-label'>Account</InputLabel>
                  <Select
                    labelId='account-select-label'
                    id='account-select'
                    {...register('account_id')}
                    label='Account'
                  >
                    {accountData?.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.account_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {paymentMethod === 'Cheque' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      id='bank_name'
                      label='Bank Name'
                      type='text'
                      variant='outlined'
                      {...register('bank_name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      id='cheque_no'
                      label='Cheque Number'
                      type='text'
                      variant='outlined'
                      {...register('cheque_no')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id='cheque_date'
                      label='Cheque Date'
                      type='date'
                      required
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      {...register('cheque_date')}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Note'
                  type='text'
                  variant='outlined'
                  multiline
                  rows={4}
                  {...register('note')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign='center' mt={3}>
          {loading ? (
            <LoadingButton
              loading={loading}
              fullWidth
              color='secondary'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant='contained'
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateTraineeExpense;
