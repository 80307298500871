import { CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { IOfflinePayemntType } from '../allInvoice/types/invoiceType';
import CommonPdfAndImageVeiw from 'src/components/pdfAndImageView/CommonPdfAndImageVeiw';

type IProps = {
  loading?: boolean;
  data: IOfflinePayemntType;
};
const OfflinePaymentCardInformation = ({ data, loading }: IProps) => {
  return (
    <>
      {!loading && data ? (
        <CardContent>
          <Grid
            container
            pt={2}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Account No :
              </Typography>
              {data?.bank_ac_no}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Amount :
              </Typography>
              {data?.amount}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Deposit Date:
              </Typography>
              {moment(data.deposit_date).format('Do-MM-YYYY')}
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Bank Name:
              </Typography>
              {data.bank_name}
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Branch Name:
              </Typography>

              {data.branch_name}
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <CommonPdfAndImageVeiw
                file={data.bank_slip}
                fileName='View Bank Slip/Document:'
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Reference No :
              </Typography>
              {data.reference_no}
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        ''
      )}
      <>
        {/* <ModalsWrapper
      modalData={{ title: "View document" }}
      setShowModal={setOpen}
      showModal={open}
    >
      <PdfAndImageView file={imageFile} />
    </ModalsWrapper> */}
      </>
    </>
  );
};

export default OfflinePaymentCardInformation;
