import { Chip, Divider, Grid, Typography } from '@mui/material';
import { imgUrl } from 'src/Helpers/utils/Constant';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import PdfAndImageView from 'src/components/pdfAndImageView/PdfAndImageView';
import { useState } from 'react';
import { CommonTypoGraphy } from 'src/components/pdfAndImageView/CommonTypoGraphy';
import OfflinePaymentCardInformation from '../../subModules/invoice/singleInvoice/OfflinePaymentCardInformation';
import { Link } from 'react-router-dom';

const IdCardInfo = ({ singleIdCard }: any) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');
  return (
    <>
      <Grid
        container
        pt={2}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Applicant Name:
          </Typography>
          {singleIdCard.applicant_name}
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Designation:
          </Typography>
          {singleIdCard.designation}
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Status:
          </Typography>
          {getStatusLabel(singleIdCard.status)}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Image :
          </Typography>
          <CommonTypoGraphy
            name='View'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(singleIdCard.photo);
            }}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Date of birth:
          </Typography>
          {moment(singleIdCard.date_of_birth).format('MMM Do YY')}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            telephone:
          </Typography>
          {singleIdCard.telephone}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Cellphone:
          </Typography>
          {singleIdCard.cellphone}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Fax Number:
          </Typography>
          {singleIdCard.fax}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Email:
          </Typography>
          {singleIdCard.email}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Blood Group:
          </Typography>
          {singleIdCard.blood_group}
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            Website:
          </Typography>
          <a
            href={singleIdCard.website}
            target='_blank'
            rel='noopener noreferrer'
          >
            {singleIdCard.website}
          </a>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Typography
            variant='body1'
            fontWeight='bold'
            color='text.primary'
            display='inline'
            sx={{ pr: 1 }}
            gutterBottom
          >
            TIN Number:
          </Typography>
          {singleIdCard.tin_number}
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <CommonTypoGraphy
            name='View TIN Certificate'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(singleIdCard.tin_file);
            }}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <CommonTypoGraphy
            name='View Trade license'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(singleIdCard.trade_license_file);
            }}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          {' '}
          <CommonTypoGraphy
            name='View Civil aviation renewed certificate'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(
                singleIdCard.civil_aviation_renewed_certificate_file
              );
            }}
          />
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <CommonTypoGraphy
            name='View Signature'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(singleIdCard.signature_file);
            }}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          {' '}
          <CommonTypoGraphy
            name='View Specimen signature'
            onClick={() => {
              SetImageViewOpen(true);
              setImageFile(singleIdCard.specimen_signature_file);
            }}
          />
        </Grid>
        {singleIdCard?.paymentInfo && (
          <Grid item xs={12}>
            <Divider
              sx={{ position: 'relative', justifyContent: 'space-between' }}
            >
              <Chip label='Payment Information' size='small' color='primary' />
              <Link
                to={`/invoices/transactions/${singleIdCard?.paymentInfo?.invoice_id}`}
              >
                <Chip
                  label='Go Invoice'
                  size='small'
                  sx={{ position: 'absolute', right: 0, background: '#D7E9FD' }}
                />
              </Link>
            </Divider>

            <OfflinePaymentCardInformation data={singleIdCard?.paymentInfo} />
          </Grid>
        )}
      </Grid>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default IdCardInfo;
