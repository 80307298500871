import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  Autocomplete,
  Chip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { HttpClient } from 'src/Helpers/http/http';
import Toaster from 'src/Toaster/Toaster';
import { LoadingButton } from '@mui/lab';
import { IAccountType } from 'src/content/modules/Members/MemberAccount/Account/types/AccountTypes';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { removeUndefinedAndNull } from 'src/common/removeUndefinedAndNull';
interface ITraineeList {
  user_training_trainee_id: number;
  user_training_trainee_name: string;
  user_training_trainee_email: string;
  user_training_trainee_photo: null;
  user_training_trainee_account_status: number;
}
export interface ITraineeInvoiceList {
  training_payment_invoice_id: number;
  invoice_no: string;
  training_payment_invoice_issue_date: string;
  training_payment_invoice_grand_total: string;
  training_payment_invoice_due: string;
  user_training_trainee_name: string;
}
const CreateMoneyReceipt = ({ setRefresh, handelClose }: any) => {
  const { register, handleSubmit, watch } = useForm();
  const [allMember, setAllMember] = useState<ITraineeList[]>([]);
  const [member, setMember] = useState<ITraineeList | null>(null);
  const [companyName, seTCompanyName] = useState<string>('');
  const [memberInvoices, setMemberInvoices] = useState<ITraineeInvoiceList[]>(
    []
  );
  const [invoices, setInvoices] = useState<any>({} as any);
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch('payment_method');
  const [accountData, setAccountData] = useState<IAccountType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/get/all/or/status/all?user_training_trainee_name=${companyName}`
        );
        if (data.success) {
          setAllMember(data.data);
        }
      } catch (error) {}
    })();
  }, [companyName]);

  useEffect(() => {
    if (member?.user_training_trainee_id) {
      (async function () {
        try {
          const data: any = await HttpClient.get(
            `${url}/api/admin/training/invoice?traineeId=${member?.user_training_trainee_id}&status=unpaid`
          );
          if (data.success) {
            setMemberInvoices(data.data);
          }
        } catch (error) {}
      })();
    }
  }, [member]);

  useEffect(() => {
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
            } else {
            }
          })
          .catch(function (err) {
            setAccountData([]);
          });
      } catch (error) {}
    })();
  }, []);
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { ...inputs };
    if (member.user_training_trainee_id) {
      submitData.trainee_id = member.user_training_trainee_id;
    }
    if (invoices.training_payment_invoice_id) {
      submitData.invoice_id = invoices.training_payment_invoice_id;
    }
    try {
      const res: any = await HttpClient.post(
        `${url}/api/admin/training/money-receipt`,
        removeUndefinedAndNull(submitData)
      );
      if (res.success) {
        Toaster().fire({
          icon: 'success',
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
        navigate(`/trainee-money-receipt/details/${res.data.id}`);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };
  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={6} item>
              <Autocomplete
                value={member || null}
                onChange={(_event, newValue) => {
                  setMember(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.user_training_trainee_id ===
                  value.user_training_trainee_id
                }
                loading={true}
                id='controllable-states-demo'
                options={allMember}
                onInputChange={(_event, newInputValue) => {
                  handelInputChange(newInputValue);
                }}
                getOptionLabel={(option) => option.user_training_trainee_name}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant='filled'
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select member'
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
            <>
              <Grid xs={12} md={6} lg={6} item>
                <Autocomplete
                  value={invoices}
                  onChange={(_event: any, newValue: any) => {
                    setInvoices(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.training_payment_invoice_id ===
                    value.training_payment_invoice_id
                  }
                  loading={true}
                  id='controllable-states-demo'
                  options={memberInvoices}
                  onInputChange={(_event, newInputValue) => {
                    handelInputChange(newInputValue);
                  }}
                  getOptionLabel={(option: ITraineeInvoiceList) =>
                    option.invoice_no || ''
                  }
                  renderTags={(
                    value: readonly ITraineeInvoiceList[],
                    getTagProps
                  ) =>
                    value.map((option: ITraineeInvoiceList, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key}
                          variant='filled'
                          label={option.invoice_no || 'No Invoice Number'}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select invoices'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
            {/* )} */}
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              item
              xs={12}
              md={6}
              lg={6}
            >
              <FormControl sx={{ mt: 1 }} fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Select payment method
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={paymentMethod}
                  label='Select payment method'
                  {...register('payment_method')}
                >
                  <MenuItem value={'Cash'}>Cash</MenuItem>
                  <MenuItem value={'Bank'}>Bank</MenuItem>

                  <MenuItem value={'Mobile Banking'}>Mobile Banking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormControl fullWidth required margin='normal'>
                <InputLabel id='demo-simple-select-label'>Account</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  {...register('account_id')}
                  label='Account'
                >
                  {accountData?.map((account) => {
                    return (
                      <MenuItem key={account.id} value={account.id}>
                        {account.account_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {paymentMethod === 'Bank' && (
              <>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id='BankName'
                    label='Bank Name'
                    type='text'
                    variant='outlined'
                    {...register('BankName')}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    id='BranchName'
                    label='Branch Name'
                    type='text'
                    variant='outlined'
                    {...register('BranchName')}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    id='ChequeNo'
                    label='Cheque Number'
                    type='text'
                    variant='outlined'
                    {...register('ChequeNo')}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    id='ChequeDate'
                    label='Cheque Date'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    {...register('ChequeDate')}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id='Amount'
                value={
                  invoices?.training_payment_invoice_grand_total
                    ? invoices?.training_payment_invoice_grand_total
                    : 0
                }
                disabled
                type='number'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id='payment_date'
                label='Payment Date'
                type='date'
                defaultValue={moment().format('YYYY-MM-DD')}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                {...register('payment_date')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                placeholder='Remark'
                required
                minRows={3}
                label='Remark'
                id='outlined-required'
                {...register('remark')}
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Box textAlign={'center'} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color='secondary'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant='contained'
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type='submit'
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create
              </Button>
            )}{' '}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateMoneyReceipt;
