import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { ISingleDuplicateCertificateApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateModal from './UpdateModal';
import PdfAndImageView from 'src/components/pdfAndImageView/PdfAndImageView';
import { CommonTypoGraphy } from 'src/components/pdfAndImageView/CommonTypoGraphy';
import OfflinePaymentCardInformation from '../../subModules/invoice/singleInvoice/OfflinePaymentCardInformation';

const SingleDuplicateCertificateApplication = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [application, setApplication] =
    useState<ISingleDuplicateCertificateApplication | null>(null);
  const navigate = useNavigate();
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');

  useEffect(() => {
    (async () => {
      try {
        const data = await axios.get(
          `${url}/api/member/duplicate-certificate-application/${id}`
        );
        if (data.data.success) {
          setApplication(data.data.data);
        }
      } catch (err) {
        alert('No application found');
        navigate('/update-applications/duplicate-certificate');
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Atab - Duplicate certificate application</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Card>
          <CardHeader
            title='Duplicate certificate application'
            action={
              <Button onClick={() => setShowModal(true)} variant='outlined'>
                Update
              </Button>
            }
          />
          <Divider />
          <CardContent>
            {application ? (
              <Grid
                container
                pt={2}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Application Date:
                  </Typography>
                  {moment(
                    application.duplicate_certificate_application_created_at
                  ).format('MMM Do YY')}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company name:
                  </Typography>
                  {application.user_member_company_name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(
                    application.duplicate_certificate_application_status
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Civil Aviation'
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.duplicate_certificate_application_civil_aviation
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Trade License'
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.duplicate_certificate_application_trade_license
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Trade License'
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.duplicate_certificate_application_gd_copy
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Application '
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.duplicate_certificate_application_application_file
                      );
                    }}
                  />
                </Grid>
                {application.duplicate_certificate_application_rejeceted_reason ? (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1, color: 'red' }}
                      gutterBottom
                    >
                      Reject reason:
                    </Typography>
                    {
                      application.duplicate_certificate_application_rejeceted_reason
                    }
                  </Grid>
                ) : (
                  ''
                )}

                {application?.paymentInfo && (
                  <Grid item xs={12}>
                    <Divider
                      sx={{
                        position: 'relative',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Chip
                        label='Payment Information'
                        size='small'
                        color='primary'
                      />
                      <Link
                        to={`/invoices/transactions/${application?.paymentInfo?.invoice_id}`}
                      >
                        <Chip
                          label='Go Invoice'
                          size='small'
                          sx={{
                            position: 'absolute',
                            right: 0,
                            background: '#D7E9FD',
                          }}
                        />
                      </Link>
                    </Divider>

                    <OfflinePaymentCardInformation
                      data={application?.paymentInfo}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>

      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleDuplicateCertificateApplication;
