import { collapseClasses } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

type IProps = {
  defaultShow?: string;
  collection: JSX.Element;
  expense: JSX.Element;
};

const ShowingDatausingTab = ({
  defaultShow = 'collection',
  collection,
  expense,
}: IProps) => {
  const [currentTab, setCurrentTab] = useState(defaultShow);

  return (
    <>
      <div style={styles.container}>
        <div style={styles.tabList}>
          <button
            style={{
              ...styles.tab,
              borderBottom:
                currentTab === 'collection'
                  ? '3px solid #1976d2'
                  : '3px solid transparent',
              color: currentTab === 'collection' ? '#1976d2' : '#666',
            }}
            onClick={() => setCurrentTab('collection')}
          >
            Collection
          </button>
          <button
            style={{
              ...styles.tab,
              borderBottom:
                currentTab === 'expense'
                  ? '3px solid #1976d2'
                  : '3px solid transparent',
              color: currentTab === 'expense' ? '#1976d2' : '#666',
            }}
            onClick={() => setCurrentTab('expense')}
          >
            Expense
          </button>
        </div>

        {/* <div style={styles.panel}>
        {currentTab === 'collection' ? (
          <div>
            <h3>Collection Data</h3>
            <p>
              Display detailed information about collections here, such as
              transaction details or amounts.
            </p>
          </div>
        ) : (
          <div>
            <h3>Expense Data</h3>
            <p>
              Display detailed information about expenses here, such as expense
              categories or notes.
            </p>
          </div>
        )}
      </div> */}
      </div>
      <Box bgcolor={'#fff'}>
        {currentTab === 'collection' ? collection : expense}
      </Box>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '200px',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
  title: {
    textAlign: 'center' as const,
    fontWeight: '600',
    color: '#333',
    marginBottom: '20px',
  },
  tabList: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px 10px',
    textAlign: 'center' as const,
    fontWeight: '500',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '3px solid transparent',
    transition: 'color 0.3s ease, border-bottom 0.3s ease',
  },
  panel: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
};

export default ShowingDatausingTab;
