import { useReactToPrint } from 'react-to-print';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { TableNotFound } from 'src/Utils/TableLoader';
import { IViewTraineeExpense } from '../types/traineeExpenseTypes';

const ViewTraineeExpense = () => {
  const { id } = useParams();
  const { response, loading } = GetDataApi<IViewTraineeExpense>(
    `/api/atab/expense/${id}?CompanyProfileId=2`
  );

  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Box ml={3}>
      {response && (
        <Card
          className='invoice_print_form'
          sx={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <CardHeader
            title={`Expense Details`}
            // action={
            //   <Tooltip title='Print Invoice'>
            //     <IconButton onClick={handlePrint}>
            //       <SimCardDownload
            //         sx={{
            //           cursor: 'pointer',
            //           color: theme.colors.primary.main,
            //         }}
            //       />
            //     </IconButton>
            //   </Tooltip>
            // }
          />
          <CardContent>
            <>
              <Box sx={{ mt: 3 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Expense Date:
                    </Typography>
                    {response.expense_date
                      ? moment(response.expense_date, 'Asia/Dhaka').format(
                          'DD-MMM-YYYY'
                        )
                      : 'Unpaid'}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Expense No:
                    </Typography>
                    {response.expense_no}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Account name :
                    </Typography>

                    {response.account_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Payment Method :
                    </Typography>

                    {response.method}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Amount :
                    </Typography>

                    {response.amount}
                  </Grid>

                  {response.bank_name && (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Bank name :
                      </Typography>

                      {response.bank_name}
                    </Grid>
                  )}
                  {response.cheque_no && (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Cheque No :
                      </Typography>

                      {response.cheque_no}
                    </Grid>
                  )}
                  {response.cheque_date && (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Cheque Date :
                      </Typography>

                      {moment(response.cheque_date, 'Asia/Dhaka').format(
                        'DD-MMM-YYYY'
                      )}
                    </Grid>
                  )}

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Remark:
                    </Typography>
                    {response.note}
                  </Grid>
                </Grid>

                <>
                  <Typography
                    sx={{ textDecoration: 'underline' }}
                    mt={8}
                    mb={3}
                    color='text.'
                    align='center'
                    variant='h4'
                  >
                    Expense Items
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: theme.colors.primary.main,
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ color: theme.colors.alpha.white[100] }}
                          >
                            SL
                          </TableCell>

                          <TableCell
                            sx={{ color: theme.colors.alpha.white[100] }}
                            align='center'
                          >
                            Item name
                          </TableCell>

                          <TableCell
                            sx={{ color: theme.colors.alpha.white[100] }}
                            align='center'
                          >
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {response?.items?.length ? (
                          <>
                            {response?.items?.map((details, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell align='center'>
                                  {details?.name}
                                </TableCell>
                                <TableCell align='center'>
                                  {details?.amount}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <TableNotFound />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </Box>
            </>
          </CardContent>
          {/* <div className='invoice_footer_form'>
            {singleInvoiceInfo && (
              <PrintSingleInvoice
                singleInvoice={singleInvoiceInfo}
                componentRef={componentRef}
              />
            )}
          </div> */}
        </Card>
      )}
    </Box>
  );
};

export default ViewTraineeExpense;
