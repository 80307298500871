import { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Container,
  Typography,
  Paper,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import { IReceiveReportType } from './types/receiveReportTypes';
import ShowingDatausingTab from './components/ShowingDatausingTab';

const ReceiveReport = () => {
  const [date, setDate] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
  });
  const { response, loading, total, getData } = GetDataApi<IReceiveReportType>(
    `/api/admin/accounts/report/income-expense?CompanyProfileId=1&from_date=${dayjs(date.startDate).format('YYYY-MM-DD')}&to_date=${dayjs(date.endDate).format('YYYY-MM-DD')}`,
    true
  );

  useEffect(() => {
    getData();
  }, [date]);
  return (
    <Container maxWidth='xl'>
      <Grid>
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <DatePicker
                  format='DD/MM/YYYY'
                  label='Start Date'
                  value={date?.startDate}
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  format='DD/MM/YYYY'
                  label='End Date'
                  value={date?.endDate}
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <ShowingDatausingTab
          defaultShow='collection'
          collection={
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Money Receipt No</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response?.collection?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          {dayjs(item.money_receipt_date).format('YYYY-MMM-DD')}
                        </TableCell>
                        <TableCell>{item.money_receipt_no}</TableCell>
                        <TableCell>{item.voucher_no}</TableCell>
                        <TableCell>{item.payment_method}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
          expense={
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Expense No</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Amount</TableCell>

                      <TableCell>Note</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response?.expense?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          {dayjs(item.expense_date).format('YYYY-MMM-DD')}
                        </TableCell>
                        <TableCell>{item.expense_no}</TableCell>
                        <TableCell>{item.voucher_no}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        />
      </Grid>
    </Container>
  );
};

export default ReceiveReport;
