import { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { IMemberInfo } from 'src/Types/module/member/memberTypes';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import Toaster from 'src/Toaster/Toaster';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
type IProps = {
  memberInfo: IMemberInfo;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
};

const CreateCertificateModal = ({ setOpen, setRefresh }: any) => {
  const { user } = useAuthContext();
  const { memberId } = useParams();
  const [issueDate, setIssueDate] = useState<Dayjs | null>(dayjs());
  const [renewDate, setRenewDate] = useState<Dayjs | null>(dayjs());
  const [certificateType, setCertificate] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ tabvalue: '' });

  const handelSubmit = async () => {
    setLoading(true);
    const C_data: any = {
      user_member_id: memberId,
      c_type: certificateType,
      c_issue_date: dayjs(issueDate).format('YYYY-MM-DD'),
    };
    if (renewDate) {
      C_data.c_renew_year = dayjs(renewDate).format('YYYY-MM-DD');
    }
    try {
      const { data } = await axios.post(
        `${url}/api/atab/user-member/certificate?user_admin_id=${user.user_admin_id}`,
        C_data
      );

      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        download(data.certificate_photo);
        setRefresh((prv) => !prv);
        setOpen(false);
        setLoading(false);
        setSearchParams({
          tabvalue: 'certificates',
        });
      } else {
        Toaster().fire({
          icon: 'failed',
          title: `${data.message}`,
        });
        setOpen(false);
        setLoading(false);
      }
    } catch (err) {
      Toaster().fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setOpen(false);
      setLoading(false);
    }
  };

  const download = (img) => {
    return <a target='_blank' href={`${imgUrl}/certificate_files/${img}`}></a>;
  };
  return (
    <Box p={2} sx={{ maxWidth: '400px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>
              Certificate type
            </InputLabel>
            <Select
              required
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={certificateType}
              label='Certificate type'
              onChange={(e) => setCertificate(e.target.value)}
            >
              <MenuItem value={'member_certificate'}>New membership</MenuItem>
              <MenuItem value={'renewal_certificate'}>
                Renewal certificate
              </MenuItem>
              <MenuItem value={'duplicate_certificate'}>
                Duplicate certificate
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Select certificate issue date'
              format='MM/DD/YYYY'
              value={issueDate}
              onChange={(e: any) => setIssueDate(e)}
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Grid>
        {certificateType === 'renewal_certificate' && (
          <Grid item xs={12} md={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label='Select certificate renew date'
                format='MM/DD/YYYY'
                value={renewDate}
                onChange={(e: any) => setRenewDate(e)}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        )}
      </Grid>

      <Box mt={3} textAlign='right'>
        {loading ? (
          <Button variant='contained'>Loading...</Button>
        ) : (
          <Button variant='contained' onClick={handelSubmit}>
            Create
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CreateCertificateModal;
