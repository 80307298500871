import React from 'react';
import { IInspection } from './type/inspectionType';
import { Box, Container, Typography } from '@mui/material';
import dayjs from 'dayjs';
import CommonInspectionView from './CommonInspectionView';
import { imgUrl } from 'src/Helpers/utils/Constant';

const ViesInspection = ({
  inspectionData,
  componentRef,
}: {
  inspectionData: IInspection;
  componentRef: any;
}) => {
  return (
    <div ref={componentRef}>
      <Container maxWidth='md'>
        {' '}
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant='h3' gutterBottom>
              আটাবের সদস্যপদের জন্য আবেদনকৃত
            </Typography>

            <Typography variant='h3' gutterBottom>
              ট্রাভেল এজেন্সী পরিদর্শন সংক্রান্ত তথ্য
            </Typography>
            <hr style={{ width: '450px' }} />
          </div>
          <div style={{ textAlign: 'end' }}>
            <p>
              পরিদর্শনের তারিখঃ{' '}
              {dayjs(inspectionData?.inspection_report_date).format(
                'DD-MM-YYYY'
              )}
            </p>
          </div>
        </div>
        <CommonInspectionView
          serial='০১'
          title='অফিসের নাম:'
          value={inspectionData?.office_name}
        />
        <CommonInspectionView
          serial='০২'
          title='ঠিকানা:'
          value={inspectionData?.addressline1}
        />
        <CommonInspectionView
          serial='০৩'
          title='এজেন্সির ধরন:'
          value={'Travel Agency'}
        />
        <CommonInspectionView
          serial='০৪'
          title='ট্রাভেল এজেন্সি লাইসেন্স হালনাগাদ আছে কি না ? '
          value={inspectionData?.agency_license_update}
        />
        <CommonInspectionView
          serial='০৫'
          title='ট্রেড লাইসেন্স হালনাগাদ আছে কি না ? '
          value={inspectionData?.trade_license_update}
        />
        <CommonInspectionView
          serial='০৬'
          title='কত কক্ষ বিশিষ্ট অফিস :'
          value={inspectionData?.total_office_room}
        />
        <CommonInspectionView
          serial='০৭'
          title='অফিসের আয়তন :'
          value={inspectionData?.total_office_area}
        />
        <CommonInspectionView
          serial='০৮'
          title='অফিস বিল্ডিং এর বিবরণ :'
          value={inspectionData?.office_building_details}
        />
        <CommonInspectionView
          serial='০৯'
          title='টেলিফোন সংযোগ আছে কি না, থাকলে কয়টি :'
          value={inspectionData?.telephone_connection}
        />{' '}
        <CommonInspectionView
          serial='১০'
          title='ওয়েবসাইট  আছে কি না?'
          value={inspectionData?.fax_connection}
        />
        <CommonInspectionView
          serial='১১'
          title='ই-মেইল সংযোগ আছে কি না? '
          value={inspectionData?.email_connection}
        />
        <CommonInspectionView
          serial='১২'
          title='দক্ষ জনবল আছে কি না?'
          value={inspectionData?.skill_employee}
        />
        {inspectionData?.skill_employee ? (
          <CommonInspectionView
            title='বিবরণঃ'
            value={inspectionData?.skill_employee_details}
          />
        ) : (
          ''
        )}
        <CommonInspectionView
          serial='১৩'
          title='কতজন স্টাফ আছে? : '
          value={inspectionData?.total_staf}
        />
        <CommonInspectionView
          serial='১৪'
          title='দৃশ্যমান সাইন বোর্ড আছে কি না? '
          value={inspectionData?.see_able_sign_board}
        />
        <CommonInspectionView
          serial='১৫'
          title='যে ব্যাংকে লেনদেন তার নাম, শাখা ও একাউন্ট নং:'
          value={inspectionData?.bank_details}
        />
        <CommonInspectionView
          serial='১৬'
          title='আটাবে কখনও ডিফল্টার হয়েছিল কি না ? '
          value={inspectionData?.atab_defilter}
        />
        <CommonInspectionView
          serial='১৭'
          title='অন্য কোন ট্রাভেল এজেন্সির স্বত্তIধিকারী কি না? '
          value={inspectionData?.own_other_travel_agency}
        />
        <Box pb={2}>
          {inspectionData?.others_agency?.length
            ? inspectionData?.others_agency?.map((agency) => {
                return (
                  <Box display={'flex'} alignItems={'center'} gap={4}>
                    <Typography variant='h5' gutterBottom>
                      নাম : {agency?.other_travel_agency_name}
                    </Typography>
                    <Typography variant='h5' gutterBottom>
                      ঠিকানা : {agency?.other_travel_agency_location}
                    </Typography>
                  </Box>
                );
              })
            : ''}
        </Box>
        <CommonInspectionView
          serial='১৮'
          title='মন্তব্য : '
          value={inspectionData?.remarks}
        />
        {/* signature  */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '15px',
          }}
        >
          {' '}
          <Typography variant='h6' gutterBottom>
            (১)পরিদর্শকের নামঃ {inspectionData?.inspector_name_1}
          </Typography>
          <Typography variant='h6' gutterBottom>
            (২)পরিদর্শকের নামঃ {inspectionData?.inspector_name_2}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '30px',
          }}
        >
          {' '}
          <div style={{ textAlign: 'center' }}>
            {inspectionData?.secretary_signature && (
              <img
                src={`${imgUrl}/${inspectionData?.secretary_signature}`}
                alt=''
                height={80}
                width={200}
                style={{ objectFit: 'contain' }}
              />
            )}

            <hr />
            <Typography variant='h5' gutterBottom>
              সচিব
            </Typography>
            <p> ঢাকা আঞ্চলিক পরিষদ</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            {' '}
            {inspectionData?.chairman_signature && (
              <img
                src={`${imgUrl}/${inspectionData?.chairman_signature}`}
                alt=''
                height={80}
                width={200}
                style={{ objectFit: 'contain' }}
              />
            )}
            <hr />
            <Typography variant='h5' gutterBottom>
              চেয়ারম্যান
            </Typography>
            <p> ঢাকা আঞ্চলিক পরিষদ</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ViesInspection;
