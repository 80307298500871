import { useState } from 'react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { Button } from '@mui/material';

type IProps = {
  excelName: string;
  excelTableHead: string[];
  excelData: any[];
  ExcelHeading?: string;
};

const CommonExcelGenerator = ({
  excelName,
  excelTableHead,
  excelData,
  ExcelHeading,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    try {
      // Creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(`${excelName}`);

      // Add two blank rows
      // worksheet.addRow([]);
      // worksheet.addRow([]);

      if (ExcelHeading) {
        //for line break use  \n
        const loveBangladeshRow = worksheet.addRow([ExcelHeading]);

        loveBangladeshRow.font = { size: 14 };
        loveBangladeshRow.alignment = {
          horizontal: 'center',
          vertical: 'top',
          wrapText: true,
        }; // Optional: Style the text
        worksheet.mergeCells(
          `A1:${String.fromCharCode(65 + excelTableHead.length - 1)}3`
        ); // Merge cells in the third row
      }

      // Add table headers
      const titleRow = worksheet.addRow(excelTableHead);
      worksheet.columns = Array.from(
        { length: excelTableHead.length },
        (_, index) => ({ width: index === 0 ? 15 : 30 })
      );

      // Style headers
      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      // Add table data
      excelData?.forEach((rowData) => {
        const values = excelTableHead.map((header) => rowData[header]);
        worksheet.addRow(values);
      });

      // Generate and download the Excel file
      const excelDataGenerate = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([excelDataGenerate]), `${excelName}.xlsx`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error('Something Went Wrong', error.message);
    }
  };

  return (
    <div>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant='outlined' size='small'>
          Download Excel
        </Button>
      )}
    </div>
  );
};

export default CommonExcelGenerator;

// import { useState } from 'react';
// import Excel from 'exceljs';
// import { saveAs } from 'file-saver';
// import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
// import { Button } from '@mui/material';

// type IProps = {
//   excelName: string;
//   excelTableHead: string[];
//   excelData: any[];
// };
// const CommonExcelGenerator = ({
//   excelName,
//   excelTableHead,
//   excelData,
// }: IProps) => {
//   const [loading, setLoading] = useState(false);
//   const workbook = new Excel.Workbook();

//   const saveExcel = async () => {
//     setLoading(true);
//     try {
//       // creating one worksheet in workbook
//       const worksheet = workbook.addWorksheet(`${excelName}`);
//       const titleRow = worksheet.addRow(excelTableHead);
//       worksheet.columns = Array.from(
//         { length: excelData?.length },
//         (x, index) => ({ width: index === 0 ? 15 : 30 })
//       );
//       titleRow.eachCell((cell) => {
//         cell.font = { bold: true };
//       });

//       excelData?.forEach((rowData) => {
//         const values = excelTableHead.map((header) => rowData[header]);
//         worksheet.addRow(values);
//       });

//       const excelDataGenerate = await workbook.xlsx.writeBuffer();
//       // download the processed file
//       saveAs(new Blob([excelDataGenerate]), `${excelName}.xlsx`);
//       setLoading(false);
//     } catch (error: any) {
//       setLoading(false);
//       console.error('Something Went Wrong', error.message);
//     }
//   };
//   return (
//     <div>
//       {loading ? (
//         <GlobalLoader />
//       ) : (
//         <Button onClick={saveExcel} variant='outlined' size='small'>
//           Download excel
//         </Button>
//       )}
//     </div>
//   );
// };

// export default CommonExcelGenerator;
