import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { imgUrl } from '../../Helpers/utils/Constant';
import { Box, Typography } from '@mui/material';
import ModalsWrapper from '../Modals/ModalsWrapper';
import { useState } from 'react';
import { CommonTypoGraphy } from './CommonTypoGraphy';
const CommonPdfAndImageVeiw = ({
  file,
  fileName,
}: {
  file: string | null;
  fileName: string;
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const type = file?.split('.')?.[1];
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box>
      <CommonTypoGraphy
        name={fileName || 'View File'}
        onClick={() => {
          setOpen(true);
        }}
      />
      <ModalsWrapper
        modalData={{ title: 'View document' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <div style={{ padding: '0px 10px' }}>
          {file && type ? (
            <>
              {type === 'pdf' ? (
                <div style={{ width: '800px' }}>
                  <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js'>
                    <div
                      style={{
                        height: '550px',
                      }}
                    >
                      <Viewer
                        fileUrl={`${imgUrl}/${file}`}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>
                </div>
              ) : (
                <>
                  <img
                    src={`${imgUrl}/${file}`}
                    alt=''
                    height={400}
                    width={400}
                  />
                  <Box>
                    <a
                      href={`${imgUrl}/${file}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Download
                    </a>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Typography py={2} px={2} textAlign={'center'} variant='h5'>
              No file found
            </Typography>
          )}
        </div>{' '}
      </ModalsWrapper>
    </Box>
  );
};

export default CommonPdfAndImageVeiw;
