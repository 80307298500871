import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { imgUrl, url } from 'src/Helpers/utils/Constant';
import { ISingleOwnershipChangeApplication } from 'src/Types/module/applications/applications';
import { getStatusLabel } from 'src/Utils/Combined';
import UpdateModal from './UpdateModal';
import PdfAndImageView from 'src/components/pdfAndImageView/PdfAndImageView';
import { CommonTypoGraphy } from 'src/components/pdfAndImageView/CommonTypoGraphy';

const SingleOwnershipChangeApplication = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [application, setApplication] =
    useState<ISingleOwnershipChangeApplication | null>(null);
  const navigate = useNavigate();
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');

  useEffect(() => {
    (async () => {
      try {
        const data = await axios.get(
          `${url}/api/member/owner-change-application/${id}`
        );

        if (data.data.success) {
          setApplication(data.data.data);
        }
      } catch (err) {
        alert('No application found');
        navigate('/update-applications/ownership-change-application');
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Atab - Ownership change application</title>
      </Helmet>
      <Card sx={{ mx: 2 }}>
        <CardHeader
          title='Ownership change application'
          action={
            <Button onClick={() => setShowModal(true)} variant='outlined'>
              Update
            </Button>
          }
        />
        <Divider />
        <CardContent>
          {application ? (
            <Grid
              container
              pt={2}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Application Date:
                </Typography>
                {moment(
                  application.ownership_change_application_created_at
                ).format('MMM Do YY')}
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Company name:
                </Typography>
                {application.user_member_company_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Status:
                </Typography>
                {getStatusLabel(
                  application.ownership_change_application_status
                )}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                {' '}
                <CommonTypoGraphy
                  name='View MOCAT Approval Copy'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_mocat_approval_copy_file
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                {' '}
                <CommonTypoGraphy
                  name='View MOCAT Certificate English Copy'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_mocat_approval_copy_file
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name='View MOCAT CertTrade License'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_trade_license
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                {' '}
                <CommonTypoGraphy
                  name='Proof of Submission of Tax Return (Acknowledgement Receipts)'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_tax_certificate
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                {' '}
                <CommonTypoGraphy
                  name='View Forwarding Letter'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_forwarding_letter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name='View Ownership Change Deed'
                  onClick={() => {
                    SetImageViewOpen(true);
                    setImageFile(
                      application.ownership_change_application_ownership_change_deed
                    );
                  }}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={12}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Note:
                </Typography>
                {application.ownership_change_application_rejected_reason}
              </Grid>

              {/* 
//
//
//
//
//
//
//
*/}

              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title='Previous owner information' />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Name:
                        </Typography>
                        {application.prev_company_owner_name}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Phone:
                        </Typography>
                        {application.prev_company_owner_phone}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Designation:
                        </Typography>
                        {application.prev_company_owner_designation}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Email:
                        </Typography>
                        {application.prev_company_owner_email}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Address:
                        </Typography>
                        {application.prev_company_owner_address}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          NID:
                        </Typography>
                        {application.prev_company_owner_nid}
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View NID'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(
                              application.prev_company_owner_nid_file
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View owner photo'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.prev_company_owner_photo);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View Biodata'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(
                              application.prev_company_owner_biodata
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View Passport'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(
                              application.prev_company_owner_passport
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View Signature'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(
                              application.prev_company_owner_signature
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* 
//
//
//
//
//
//
//
*/}

              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title='New owner information' />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Name:
                        </Typography>
                        {application.company_owner_name}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Phone:
                        </Typography>
                        {application.company_owner_phone}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Designation:
                        </Typography>
                        {application.company_owner_designation}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Email:
                        </Typography>
                        {application.company_owner_email}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Address:
                        </Typography>
                        {application.company_owner_address}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant='body1'
                          fontWeight='bold'
                          color='text.primary'
                          display='inline'
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          NID:
                        </Typography>
                        {application.company_owner_nid}
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View NID'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.company_owner_nid_file);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {' '}
                        <CommonTypoGraphy
                          name='View Owner Photo'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.company_owner_photo);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CommonTypoGraphy
                          name='View Biodata'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.company_owner_biodata);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {' '}
                        <CommonTypoGraphy
                          name='View Passport'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.company_owner_passport);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {' '}
                        <CommonTypoGraphy
                          name='View Signature'
                          onClick={() => {
                            SetImageViewOpen(true);
                            setImageFile(application.company_owner_signature);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              No Data found
            </Typography>
          )}
        </CardContent>
      </Card>
      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleOwnershipChangeApplication;
