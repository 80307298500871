import React, { useState, useEffect } from "react";
import {
  Divider,
  Card,
  CardHeader,
  Container,
  Grid,
  Box,
  Tab,
} from "@mui/material";
import axios from "axios";
import { IRequestCompanyInfo } from "src/Types/module/UpdateRequest/RequestCompanyInfo";
import { url } from "src/Helpers/utils/Constant";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CompanyInformationIndex from "./CompanyInformationIndex";

const RequestCompanyInfo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<IRequestCompanyInfo[]>([]);
  const [value, setValue] = React.useState("pending");

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const { data } = await axios.get(
          `${url}/api/atab/member-company/update-request/member/company?status=${value}`
        );

        if (data.success) {
          setCompanyInfo(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [value]);

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <TabContext value={value}>
            <Card>
              <CardHeader
                title="Company information"
                action={
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderColor: "divider" }}>
                      <TabList
                        onChange={(_event, newValue) => setValue(newValue)}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Pending" value="pending" />
                        <Tab label="Approved" value="approved" />
                        <Tab label="Rejected" value="rejected" />
                      </TabList>
                    </Box>
                  </Box>
                }
              />
              <Divider />
              <TabPanel value="pending">
                <CompanyInformationIndex
                  isLoading={isLoading}
                  companyInfo={companyInfo}
                />
              </TabPanel>
              <TabPanel value="approved">
                <CompanyInformationIndex
                  isLoading={isLoading}
                  companyInfo={companyInfo}
                />
              </TabPanel>
              <TabPanel value="rejected">
                <CompanyInformationIndex
                  isLoading={isLoading}
                  companyInfo={companyInfo}
                />
              </TabPanel>
            </Card>
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestCompanyInfo;
