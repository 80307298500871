import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HttpClient } from 'src/Helpers/http/http';
import { url } from 'src/Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { ISingleTraineeMoneyReceipt } from '../types/traineeMoneyReceiptTypes';
import TraineeMoneyReceipt from '../components/TraineeMoneyReceipt';

const TraineeSingleMoneyReceipt = () => {
  const { id } = useParams();
  const [singleMoneyReceipt, setSingleMoneyReceipt] =
    useState<ISingleTraineeMoneyReceipt>({} as ISingleTraineeMoneyReceipt);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const res: any = await HttpClient.get(
          `${url}/api/admin/training/money-receipt/${id}`
        );

        if (res.success) {
          setSingleMoneyReceipt(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Atab - Single money receipt</title>
      </Helmet>

      <Container sx={{ mt: 5 }} maxWidth='lg'>
        {!loading ? (
          <TraineeMoneyReceipt
            singleInvoiceInfo={singleMoneyReceipt}
            setSingleInvoiceInfo={setSingleMoneyReceipt}
          />
        ) : (
          <GlobalLoader />
        )}
      </Container>
    </div>
  );
};

export default TraineeSingleMoneyReceipt;
