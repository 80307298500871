import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { IDocumentPdfPrintInfo } from 'src/Types/module/member/AdmissionFromData';
import CommonCheckTable from './CommonCheckTable';

const ThirdPageForm = ({ memberId }: { memberId: number }) => {
  const [FormInfo, setFromInfo] = useState<IDocumentPdfPrintInfo>(
    {} as IDocumentPdfPrintInfo
  );

  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/member/print-member/form/${memberId}`
      );
      if (data.success) {
        setFromInfo(data.data);
      }
    })();
  }, [memberId]);

  const moneyFormat = (value: number) => {
    const money = new Intl.NumberFormat('en-BD', {
      maximumSignificantDigits: 3,
    }).format(value);
    return money;
  };

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          mb: 2,
          pt: 3,
        }}
      >
        <Typography
          fontSize={18}
          sx={{
            border: '2px solid gray',
            display: 'inline-block',
            p: 0.5,
            borderRadius: '4px',
            textTransform: 'uppercase',
          }}
        >
          Check list for membership
        </Typography>
      </Box>
      <table className='member-info-print document-submit'>
        <thead>
          <tr>
            <th colSpan={8}>Documents to be Submitted</th>
            <th colSpan={2}>Received</th>
          </tr>
        </thead>
        <tbody>
          <CommonCheckTable
            serial={1}
            title={`Pay order of TK
          ${moneyFormat(
            Number(FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount) +
              Number(
                FormInfo?.annualSubscriptionFee
                  ?.atab_paymentable_item_bdt_amount
              ) +
              Number(FormInfo?.idCardFee?.atab_paymentable_item_bdt_amount)
          )}
          from any Bank in Favor of ATAB`}
            value={FormInfo?.checkPaymentSatus}
          />
          <CommonCheckTable
            serial={2}
            title={`Two copies of latest passport size color photographs of owner`}
            value={FormInfo?.company_owner_photo}
          />
          <CommonCheckTable
            serial={3}
            title={`Photocopy of current trade license`}
            value={FormInfo?.company_trade_license}
          />
          <CommonCheckTable
            serial={4}
            title={`Proof of Submission of Tax Return (Acknowledgement Receipt)`}
            value={FormInfo?.company_tin_file}
          />
          <CommonCheckTable
            serial={5}
            title={`Photocopy of certificate issued by Ministry of Civil Aviation and
              Tourism (MOCAT)`}
            value={FormInfo?.certificate_file}
          />
          <CommonCheckTable
            serial={6}
            title={`Photocopy of NID / Passport`}
            value={FormInfo?.company_owner_nid}
          />
          <CommonCheckTable
            serial={7}
            title={`Photocopy of Incorporation Certificate (Only for Limited Company)`}
            value={FormInfo?.company_incorporation_certificate}
          />
          <CommonCheckTable
            serial={8}
            title={`Photocopy of Company memorandum & articles (Only for Limited
              Company)`}
            value={FormInfo?.company_memorandum_file}
          />
          <CommonCheckTable
            serial={9}
            title={`Photocopy of Deed partnership (Only for Partnership Business)`}
            value={FormInfo?.company_partnership_deed_file}
          />
          <CommonCheckTable
            serial={10}
            title={`Deed of agreement of Rental Office space / Land Ownership
              Documents`}
            value={FormInfo?.company_agreement_file}
          />
          <CommonCheckTable
            serial={11}
            title={`Personal details of Owner/Owners/Bio Data`}
            value={FormInfo?.company_owner_biodata}
          />
          <CommonCheckTable
            serial={12}
            title={`Internal & External Photographs of Office`}
            value={FormInfo?.company_office_photographs?.length}
          />
          <CommonCheckTable
            serial={13}
            title={`At least one staff should have Certificate either from NHTTI
              (National Hotel & Tourism Training Institute) or Airline (Biman
              Bangladesh of Airlines) or ATTI (ATAB Tourism Training Institute)
              or From any GDS (Sabre/Galileo/Amadeus).`}
            value={FormInfo?.company_one_staff_certificate}
          />
          <CommonCheckTable
            serial={14}
            title={`Staff list on office pad with seal and signature of owner/Managing
              Director/Managing Partner.`}
            value={FormInfo?.company_staff_list_file}
          />
          <CommonCheckTable
            serial={15}
            title={`ATAB Membership ID Card Form (Format available at ATAB Website
              www.atab.org.bd).`}
            value={FormInfo?.checkIdCard}
          />
          <CommonCheckTable
            serial={16}
            title={`Upload 03 ( three months ) latest sales statement`}
            value={FormInfo?.company_sales_statement}
          />
          <CommonCheckTable
            serial={17}
            title={`Upload 03 ( three months ) latest bank statement`}
            value={FormInfo?.company_bank_statement}
          />
        </tbody>
      </table>
      <Typography fontWeight={'600'} my={1}>
        N.B.: Complete application form should be submitted directly at ATAB
        Office or can be sent by registered post. (Incomplete Application will
        not be honored)
      </Typography>
      <Typography fontWeight={'600'} my={1} fontSize={14}>
        Description of Membership/Yearly Subscription/ID Card Fees chart is as
        below.
      </Typography>
      <Box mt={2}>
        <table className='member-info-print'>
          <thead>
            <tr className='business-license-table-head'>
              <th>Membership Fee</th>
              <th>Annual Subscriptions For Three Years</th>
              <th> ID Card Fee</th>
              <th> In Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className='business-license-table-body '>
              <td>
                BDT{' '}
                {moneyFormat(
                  Number(
                    FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount
                  )
                )}
              </td>
              <td>
                BDT{' '}
                {moneyFormat(
                  Number(
                    FormInfo?.annualSubscriptionFee
                      ?.atab_paymentable_item_bdt_amount
                  )
                )}
                (
                {moneyFormat(
                  Number(
                    FormInfo.annualSubscriptionFee
                      ?.atab_paymentable_item_bdt_amount
                  ) / 3
                )}
                x 3)
              </td>
              <td>
                BDT {FormInfo.idCardFee?.atab_paymentable_item_bdt_amount}
              </td>
              <td>
                BDT{' '}
                {moneyFormat(
                  Number(
                    FormInfo?.membershipFee?.atab_paymentable_item_bdt_amount
                  ) +
                    Number(
                      FormInfo?.annualSubscriptionFee
                        ?.atab_paymentable_item_bdt_amount
                    ) +
                    Number(
                      FormInfo?.idCardFee?.atab_paymentable_item_bdt_amount
                    )
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Typography fontWeight={'600'} my={2} textAlign={'center'}>
        -:End:-
      </Typography>
    </>
  );
};

export default ThirdPageForm;
