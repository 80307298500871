import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';

const CommonDateRangePicker = ({
  setDateRange,
  dateRange,
}: {
  dateRange: {
    start_date: string;
    end_date: string;
  };
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      start_date: string;
      end_date: string;
    }>
  >;
}) => {
  // const [cleared, setCleared] = React.useState<boolean>(false);
  // React.useEffect(() => {
  //   if (cleared) {
  //     const timeout = setTimeout(() => {
  //       setCleared(false);
  //     }, 1500);

  //     return () => clearTimeout(timeout);
  //   }
  //   return () => {};
  // }, [cleared]);
  return (
    <Grid container alignContent={'center'} spacing={1}>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            columnGap: '5px',
          }}
        >
          {/* <Typography
            fontWeight={'bold'}
            variant='h6'
            component='h6'
            gutterBottom
          >
            From
          </Typography> */}
          <DatePicker
            sx={{ width: '100%' }}
            className='date_picker_custom'
            label='Start Date'
            format='DD/MM/YYYY'
            value={dateRange?.start_date ? dayjs(dateRange?.start_date) : null}
            onChange={(e: any) =>
              setDateRange((prev) => ({
                ...prev,
                start_date: dayjs(e).format('YYYY-MM-DD'),
              }))
            }
            // slotProps={{
            //   field: { clearable: true, onClear: () => setCleared(true) },
            // }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
          }}
        >
          <Typography
            fontWeight={'bold'}
            variant='h6'
            component='h6'
            gutterBottom
          >
            To
          </Typography>
          <DatePicker
            sx={{ width: '100%', marginLeft: '8px' }}
            className='date_picker_custom'
            label='End Date'
            format='DD/MM/YYYY'
            value={dayjs(dateRange?.end_date)}
            onChange={(e: any) =>
              setDateRange((prev) => ({
                ...prev,
                end_date: dayjs(e).format('YYYY-MM-DD'),
              }))
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CommonDateRangePicker;
