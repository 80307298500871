import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HttpClient } from 'src/Helpers/http/http';
import { CustomTextArea } from 'src/components/CustomTextArea';
import Toaster from 'src/Toaster/Toaster';
import { useNavigate } from 'react-router-dom';

// import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const AddInspectionInformation = ({
  forwarderId,
  memberId,
  handelClose,
}: {
  forwarderId: number;
  memberId: string;
  handelClose: any;
}) => {
  // const { user } = useAuthContext();
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [otherCompany, setOtherCompany] = useState([
    { other_travel_agency_name: '', other_travel_agency_location: '' },
  ]);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );

  const handleChangeInput = (index, event) => {
    const values = [...otherCompany];
    values[index][event.target.name] = event.target.value;
    setOtherCompany(values);
  };
  const handleAddFields = () => {
    setOtherCompany([
      ...otherCompany,
      { other_travel_agency_name: '', other_travel_agency_location: '' },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...otherCompany];
    values.splice(index, 1);
    setOtherCompany(values);
  };

  const onSubmit = async (data: any) => {
    data.user_member_id = memberId;

    data.application_forwarder_id = forwarderId;
    setLoading(true);
    const formData = new FormData();

    if (otherCompany?.length) {
      formData.append('others_agency', JSON.stringify(otherCompany));
    }
    formData.append(
      'inspection_report_date',
      dayjs(selectedDate).format('YYYY-MM-DD')
    );
    for (const key in data) {
      if (data[key] !== '' && data[key] !== undefined) {
        if (key === 'secretary_signature' || key === 'chairman_signature') {
          formData.append(key, data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    try {
      const res: any = await HttpClient.post(
        `/api/inspcetion-report`,
        formData
      );

      if (res.success) {
        Toaster().fire({
          icon: 'success',
          title: res.message,
        });
        handelClose();
        setLoading(false);
        navigate('/member-list/checker-list');
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  const telephoneConnection = watch('telephone_connection');
  const skillEmployee = watch('skill_employee');
  const ownOtherTravelAgency = watch('own_other_travel_agency');

  return (
    <Box>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} columns={24}>
          {' '}
          <Grid item xs={24} md={12} lg={12}>
            {' '}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label='Inspection date'
                format='MM/DD/YYYY'
                value={selectedDate}
                slotProps={{ textField: { fullWidth: true } }}
                onChange={(e) => setSelectedDate(e)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Is the travel agency license up to date?
              </InputLabel>
              <Select
                required
                {...register('agency_license_update')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Is the travel agency license up to date?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Is the trade license up to date or not?
              </InputLabel>
              <Select
                required
                {...register('trade_license_update')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Is the trade license up to date or not?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <TextField
              required
              {...register('total_office_room')}
              id='outlined-basic'
              variant='outlined'
              label='Enter office room'
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <TextField
              required
              {...register('total_office_area')}
              id='outlined-basic'
              variant='outlined'
              label='Enter office size'
              type='text'
              fullWidth
            />
          </Grid>{' '}
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Is there a telephone connection?
              </InputLabel>
              <Select
                required
                {...register('telephone_connection')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Is the trade license up to date or not?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>{' '}
          {telephoneConnection === '1' && (
            <Grid item xs={24} md={12} lg={12}>
              <TextField
                required
                {...register('total_telephone_connection')}
                id='outlined-basic'
                variant='outlined'
                label='Number of telephone connection'
                type='number'
                fullWidth
              />
            </Grid>
          )}{' '}
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Is there a website?
              </InputLabel>
              <Select
                required
                {...register('fax_connection')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Is there a website?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Is there an e-mail connection?
              </InputLabel>
              <Select
                required
                {...register('email_connection')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Is there an e-mail connection?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Whether there is skilled manpower or not
              </InputLabel>
              <Select
                required
                {...register('skill_employee')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Whether there is skilled manpower or not'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {skillEmployee === '1' && (
            <Grid item xs={24} md={24} lg={24}>
              {' '}
              <CustomTextArea
                required={true}
                placeholder='Enter description'
                name={'skill_employee_details'}
                register={register}
              />
            </Grid>
          )}
          <Grid item xs={24} md={12} lg={12}>
            <TextField
              required
              {...register('total_staf')}
              id='outlined-basic'
              label='Number of staff'
              placeholder='Number of staff'
              variant='outlined'
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Are there visible sign boards?
              </InputLabel>
              <Select
                required
                {...register('see_able_sign_board')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Are there visible sign boards?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>{' '}
          <Grid item xs={24} md={12} lg={12}>
            <TextField
              required
              {...register('bank_details')}
              id='outlined-basic'
              label='Bank name, branch and account no'
              placeholder='Bank name, branch and account no'
              variant='outlined'
              type='text'
              fullWidth
            />
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Have you ever defaulted?
              </InputLabel>
              <Select
                required
                {...register('atab_defilter')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Have you ever defaulted??'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Whether or not the proprietor of any other travel agency?
              </InputLabel>
              <Select
                required
                {...register('own_other_travel_agency')}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Whether or not the proprietor of any other travel agency?'
              >
                <MenuItem value={'1'}>YES</MenuItem>
                <MenuItem value={'0'}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>{' '}
          {ownOtherTravelAgency === '1' ? (
            <Grid item xs={24} md={24} lg={24}>
              {otherCompany.map((inputField, index) => {
                return (
                  <Grid key={index} container spacing={2} columns={24} pb={2}>
                    <Grid item xs={24} md={10} lg={10}>
                      {' '}
                      <TextField
                        fullWidth
                        name='other_travel_agency_name'
                        label='Agency name'
                        variant='outlined'
                        value={inputField.other_travel_agency_name}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                    </Grid>

                    <Grid item xs={24} md={10} lg={10}>
                      {' '}
                      <TextField
                        fullWidth
                        name='other_travel_agency_location'
                        label='Agency address'
                        variant='outlined'
                        value={inputField.other_travel_agency_location}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                    </Grid>
                    <Grid item xs={24} md={4} lg={4}>
                      {index > 0 && index !== 0 ? (
                        <IconButton
                          onClick={() => handleRemoveFields(index)}
                          title='Remove'
                        >
                          <RemoveIcon />
                        </IconButton>
                      ) : (
                        ''
                      )}

                      {index === 0 && (
                        <IconButton
                          onClick={() => handleAddFields()}
                          title='Add'
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            ''
          )}{' '}
          {/* {user?.user_admin_role === "Super Admin" && (
            <>
              <Grid xs={24} md={12} lg={12} item>
                <TextField
                  required
                  id="outlined-basic"
                  {...register("inspector_name_1")}
                  label="First inspector name"
                  placeholder="First inspector name"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid xs={24} md={12} lg={12} item>
                <TextField
                  id="outlined-basic"
                  {...register("inspector_name_2")}
                  label="Second inspector name"
                  placeholder="Second inspector name"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid xs={24} md={12} lg={12} item>
                <TextField
                  id="outlined-basic"
                  {...register("secretary_signature")}
                  label="Upload secretary signature "
                  placeholder="Upload secretary signature "
                  variant="outlined"
                  type="file"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid xs={24} md={12} lg={12} item>
                <TextField
                  id="outlined-basic"
                  {...register("chairman_signature")}
                  label="Upload chairman signature "
                  placeholder="Upload chairman signature "
                  variant="outlined"
                  type="file"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </>
          )} */}
          <Grid xs={24} md={12} lg={12} item>
            {' '}
            <CustomTextArea
              required={true}
              placeholder='Description of office building'
              name={'office_building_details'}
              register={register}
            />
          </Grid>
          <Grid item xs={24} md={12} lg={12}>
            {' '}
            <CustomTextArea
              required={true}
              placeholder='Enter comment'
              name={'remarks'}
              register={register}
            />
          </Grid>
        </Grid>{' '}
        <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
          {loading ? (
            <Button
              disabled
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              loading..
            </Button>
          ) : (
            <Button
              type='submit'
              variant='contained'
              disableElevation
              className='add-remove-button'
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddInspectionInformation;
