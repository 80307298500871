import { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Imembers } from 'src/Types/module/member/memberTypes';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { TableLoader } from 'src/Utils/TableLoader';
import { IpaymentableItem } from 'src/Types/module/invoice/invoiceTypes';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import GetDataApi from 'src/common/FetchData/GetDataApi';
import CommonExcelGenerator from 'src/common/CommonExcelGenerator';
import moment from 'moment';

type IFeeWiseType = {
  user_member_id: number;
  user_member_company_name: string;
  voucher_date: string;
  voucher_no: string;
  total_amount: string;
  item_amount: string;
  total_due: string;
};
const FeeWiseMemberReport = () => {
  const [date, setDate] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate: dayjs().subtract(1, 'month'), // 1 month before the current date
    endDate: dayjs(),
  });

  const [statusItem, setStatusItem] = useState<{
    type: string;
    item_id: number | string;
  }>({
    type: 'invoice',
    item_id: '',
  });

  const [invoiceItem, setInvoiceItem] = useState<IpaymentableItem[]>([]);
  const { response, loading, total, getData } = GetDataApi<IFeeWiseType[]>(
    `/api/atab/member/report/fee-wise-report?item_id=${statusItem.item_id}&type=${statusItem.type}&from_date=${'2022-01-01'}&to_date=${dayjs(date.endDate).format('YYYY-MM-DD')}`,
    true
  );

  console.log(response);
  useEffect(() => {
    getData();
  }, [date, statusItem]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${url}/api/atab/payment/get/all/paymentable/item`
      );

      if (data.success) {
        setInvoiceItem(data.data);
      }
    })();
  }, []);
  const getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }> = async () => {
    try {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/download/member/data/for-admin?status=${statusItem.type}&invoice_item=${statusItem.item_id}`
      );

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <DatePicker
                  label='Start Date'
                  value={date?.startDate}
                  format='DD/MM/YYYY'
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={'bold'}
                  variant='h6'
                  component='h6'
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  format='DD/MM/YYYY'
                  label='End Date'
                  value={date?.endDate}
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                />
              </Grid>

              <Grid item>
                <FormControl sx={{ width: '120px' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Select type
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={statusItem.type}
                    label='Select invoice status'
                    onChange={(e: any) =>
                      setStatusItem({
                        type: e.target.value,
                        item_id: statusItem.item_id,
                      })
                    }
                  >
                    <MenuItem value={'money_receipt'}>Money Receipt</MenuItem>
                    <MenuItem value={'invoice'}>Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: '120px' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Invoice item
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={statusItem.item_id}
                    label='Select invoice item'
                    onChange={(e: any) =>
                      setStatusItem({
                        item_id: e.target.value,
                        type: statusItem.type,
                      })
                    }
                  >
                    {invoiceItem?.map((item: IpaymentableItem) => {
                      return (
                        <MenuItem
                          key={item.atab_paymentable_item_id}
                          value={item.atab_paymentable_item_id}
                        >
                          {item.atab_paymentable_item_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`Fee Wise Members (${total})`}
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {/* <ExcelGenerator getDownloadData={getDownloadData} /> */}
                  <CommonExcelGenerator
                    excelData={
                      response?.length
                        ? response?.map((sData, index) => {
                            const data = {
                              SL: index + 1,
                              Date: moment(sData?.voucher_date).format('LLL'),
                              'Voucher No': sData.voucher_no,
                              'Company Name': sData.user_member_company_name,
                              'Item Amount': sData.item_amount,
                              'Total Amount': sData.total_amount,
                              'Due Amount': sData.total_due,
                            };
                            return data;
                          })
                        : []
                    }
                    excelTableHead={[
                      'SL',
                      'Date',
                      'Voucher No',
                      'Company Name',
                      'Item Amount',
                      'Total Amount',
                      'Due Amount',
                    ]}
                    excelName='Fee wise members'
                  />
                  {/* <CommonPdfGenerator getDownloadData={getDownloadData} /> */}
                </Box>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Voucher No</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Item Amount</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Due Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    response?.map((item) => (
                      <TableRow key={item.user_member_id}>
                        <TableCell>
                          {dayjs(item.voucher_date).format('YYYY-MMM-DD')}
                        </TableCell>
                        <TableCell>{item.voucher_no}</TableCell>
                        <TableCell>{item.user_member_company_name}</TableCell>

                        <TableCell>{item.item_amount}</TableCell>
                        <TableCell>{item.total_amount}</TableCell>
                        <TableCell>{item.total_due}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FeeWiseMemberReport;
