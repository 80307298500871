import { Dispatch, SetStateAction } from 'react';
import { singleMoneyReceipt } from 'src/Types/module/common/commonTypes';
import { Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import MoneyReceiptItems from './MoneyReceiptItems';
import dayjs from 'dayjs';

type IProps = {
  showModal?: boolean;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  singleInvoiceDetails: singleMoneyReceipt;
  setSingleInvoiceInfo?: any;
};
const MoneyReceiptBillingInfo = ({ singleInvoiceDetails }: IProps) => {
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Company name :
            </Typography>
            <Link to={`/member-list/${singleInvoiceDetails?.user_member_id}`}>
              {singleInvoiceDetails?.user_member_company_name}
            </Link>
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Money Receipt Date:
            </Typography>
            {singleInvoiceDetails?.created_at
              ? moment
                  .tz(singleInvoiceDetails?.created_at, 'Asia/Dhaka')
                  .format('DD-MM-YYYY')
              : 'Unpaid'}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Money Receipt No:
            </Typography>
            {singleInvoiceDetails?.money_receipt_no}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Voucher No:
            </Typography>
            {singleInvoiceDetails?.voucher_no}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Amount:
            </Typography>
            {singleInvoiceDetails?.total_collected_amount}
          </Grid>
          {singleInvoiceDetails?.bank_name && (
            <Grid item xs={2} sm={3} md={3}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Bank Name:
              </Typography>
              {singleInvoiceDetails?.bank_name}
            </Grid>
          )}
          {singleInvoiceDetails?.branch_name && (
            <Grid item xs={2} sm={3} md={3}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Branch Name:
              </Typography>
              {singleInvoiceDetails?.branch_name}
            </Grid>
          )}
          {singleInvoiceDetails?.cheque_number && (
            <Grid item xs={2} sm={3} md={3}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Cheque Number:
              </Typography>
              {singleInvoiceDetails?.cheque_number}
            </Grid>
          )}
          {singleInvoiceDetails?.withdraw_date && (
            <Grid item xs={2} sm={3} md={3}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Withdraw Date:
              </Typography>
              {singleInvoiceDetails?.withdraw_date &&
                dayjs(singleInvoiceDetails.withdraw_date).format('DD/MM/YYYY')}
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Remark:
            </Typography>
            {singleInvoiceDetails?.description}
          </Grid>
        </Grid>

        {/* <UpdateInvoice
          showModal={showModal}
          setShowModal={setShowModal}
          singleInvoiceDetails={singleInvoiceDetails}
          setSingleInvoiceInfo={setSingleInvoiceInfo}
        /> */}

        {/* Course item  */}
        <MoneyReceiptItems singleInvoiceDetails={singleInvoiceDetails} />
      </Box>
    </div>
  );
};

export default MoneyReceiptBillingInfo;
