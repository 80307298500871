import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment';
import { TableNotFound } from 'src/Utils/TableLoader';
import { singleMoneyReceipt } from 'src/Types/module/common/commonTypes';
import { number } from 'prop-types';

const MoneyReceiptItems = ({
  singleInvoiceDetails,
}: {
  singleInvoiceDetails: singleMoneyReceipt;
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography
        sx={{ textDecoration: 'underline' }}
        mt={8}
        mb={3}
        color='text.'
        align='center'
        variant='h4'
      >
        Money Receipt Items
      </Typography>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Year
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singleInvoiceDetails?.details?.length ? (
              <>
                {singleInvoiceDetails?.details?.map((details, index) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{details?.item_name}</TableCell>
                    <TableCell align='center'>{details?.year}</TableCell>
                    <TableCell align='center'>
                      {Number(details?.collected_amount) *
                        Number(details.quantity)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MoneyReceiptItems;
