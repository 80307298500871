import {
  Card,
  CardContent,
  Container,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import BillingInfo from './BillingInfo';
import useTheme from '@mui/material/styles/useTheme';
import EditIcon from '@mui/icons-material/Edit';

const CardMeeting = ({
  singleInvoiceInfo,
  editMode,
  handleEditModeChange,
}: any) => {
  const theme = useTheme();

  return (
    <Container>
      <Card className='invoice_print_form'>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'cneter',
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                gutterBottom
              >
                Details of
              </Typography>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pl: 1 }}
                gutterBottom
              >
                ( {singleInvoiceInfo?.member_meeting_title} )
              </Typography>
            </Box>
            <Tooltip title='Edit meeting'>
              <IconButton onClick={handleEditModeChange}>
                <EditIcon
                  sx={{ cursor: 'pointer', color: theme.colors.primary.main }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />{' '}
          {singleInvoiceInfo && (
            <BillingInfo
              editMode={editMode}
              handleEditModeChange={handleEditModeChange}
              singleInvoiceDetails={singleInvoiceInfo}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardMeeting;
