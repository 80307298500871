import { Typography, Box } from '@mui/material';
import { ToWords } from 'to-words';
import atab_logo from '../../../../../../../../assets/image/atab-logo.png';
import moment from 'moment';
import { singleMoneyReceipt } from 'src/Types/module/common/commonTypes';
import dayjs from 'dayjs';

const toWords = new ToWords({
  localeCode: 'en-BD',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      //  can be used to override defaults for the selected locale
      name: 'Taka',
      plural: 'Taka',
      symbol: '৳',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paisa',
        symbol: '',
      },
    },
  },
});
const MoneyReceiptDemo = ({
  singleMoneyReceipt,
}: {
  singleMoneyReceipt: singleMoneyReceipt;
}) => {
  return (
    <>
      <Box
        width={'100%'}
        sx={{ position: 'relative', border: '1px solid black' }}
      >
        <p style={{ position: 'absolute', top: 0, right: 4, fontSize: '11px' }}>
          Print : {dayjs().format('DD-MM-YYYY')}
        </p>
        {/* Header bar */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '10px',
            textAlign: 'center',
          }}
        >
          {/* for logo */}

          <img
            src={`${atab_logo}`}
            alt='Atab Logo'
            loading='lazy'
            height='50px'
          />

          <Box pt={1}>
            <Typography sx={{ fontSize: '11px' }}>
              Sattara Center (15th Floor), 30/A Naya Paltan, VIP Road,
              Dhaka-1000.
            </Typography>
            <Typography sx={{ fontSize: '11px' }}>
              Tel: +880 2 48320728, 8332712, Email: atab@atab.org.bd,
              www.atab.org.bd
            </Typography>
            <Typography sx={{ fontSize: '20px' }}>Money Receipt</Typography>
          </Box>
        </Box>
        {/* mr and date  */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            py: 1,
            px: 5,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1.5px solid black',
              padding: '5px 20px 0 5px',
            }}
          >
            <Typography
              variant='subtitle2'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              MR No- {''}
              {singleMoneyReceipt?.money_receipt_no}
            </Typography>
          </Box>
          <Box>
            <Typography variant='subtitle1' gutterBottom>
              <span style={{ fontWeight: 'bold' }}>Date: </span>
              {moment(singleMoneyReceipt?.created_at).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Box>
        {/* received from */}
        <Box sx={{ pb: 1 }} pl={5}>
          <Typography sx={{ fontWeight: 'bold' }} variant='h4' gutterBottom>
            <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
              Received with thanks from:{' '}
            </span>
            {singleMoneyReceipt?.user_member_company_name}
          </Typography>
        </Box>
        {/* Invoice table  */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <table
            cellPadding='2px'
            cellSpacing='0'
            style={{
              width: '88%',
              borderCollapse: 'collapse',
              border: '1px solid black',
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                  }}
                >
                  Item Name
                </th>
                {/* <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                  }}
                >
                  Instruments
                </th> */}
                <th
                  style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                  }}
                >
                  Year
                </th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {singleMoneyReceipt?.details?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      padding: '2px',
                    }}
                  >
                    <td
                      style={{
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                      }}
                    >
                      {item.item_name}
                    </td>
                    {/* <td
                      style={{
                        border: "1px solid black",
                        borderCollapse: "collapse",
                      }}
                    >
                      Cheque/PO No.: {item.cheque_no}
                    </td> */}
                    <td
                      align='center'
                      style={{
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                      }}
                    >
                      {item.year}
                    </td>

                    <td
                      style={{
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                        textAlign: 'right',
                      }}
                    >
                      {`${Number(item.collected_amount)}`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td
                  style={{
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                  }}
                >
                  Total Amount Taka:
                </td>
                <td
                  colSpan={3}
                  style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  {Number(singleMoneyReceipt?.total_collected_amount)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* money and description */}
        <Box pl={4}>
          <Typography sx={{ padding: '15px 0 0 10px', fontSize: '12px' }}>
            <span style={{ fontWeight: 'bold' }}>In Words: </span>
            {toWords.convert(
              singleMoneyReceipt?.total_collected_amount
                ? Number(singleMoneyReceipt?.total_collected_amount)
                : 0
            )}
          </Typography>
          <Typography sx={{ padding: '10px 0 0 10px', fontSize: '12px' }}>
            <span style={{ fontWeight: 'bold' }}>Description : </span>{' '}
            {singleMoneyReceipt.description}
          </Typography>
        </Box>
        {/* Signature  */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: 3,
              alignItems: 'center',
              pb: 1,
            }}
          >
            {/* <p>{singleMoneyReceipt?.authorized_person}</p> */}
            <hr
              style={{
                border: '0.5px solid black',
                width: '250px',
                color: 'black',
              }}
            />
            <Typography>Authorized</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              pt: 3,
              pb: 1,
            }}
          >
            <hr
              style={{
                border: '0.5px solid black',
                width: '250px',
                color: 'black',
              }}
            />
            <Typography>Accounts</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MoneyReceiptDemo;
