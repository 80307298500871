import {
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { Link, useParams } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';
import { Box } from '@mui/system';
import { HttpClient } from 'src/Helpers/http/http';
import { IInvoice } from '../../invoice/allInvoice/types/invoiceType';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';

const CompanyInvoices = () => {
  const { memberId } = useParams();
  const [loading, setLoading] = useState(false);
  const [memberInvoice, setMemberInvoice] = useState<IInvoice[]>([]);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/admin/invoice?memberId=${memberId}`;

        const data: any = await HttpClient.get(uri);
        if (data.success) {
          setMemberInvoice(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId]);
  return (
    <CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SL</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Invoice no</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Due Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : memberInvoice?.length ? (
              <>
                {memberInvoice?.map((sInvoice, index: number) => (
                  <TableRow hover>
                    <TableCell>{index + 1}</TableCell>

                    <TableCell>
                      {moment(sInvoice.atab_payment_invoice_issue_date).format(
                        'DD-MMM-YYYY'
                      )}
                    </TableCell>
                    <TableCell>{sInvoice.atab_payment_invoice_no}</TableCell>
                    <TableCell>{sInvoice.user_member_company_name}</TableCell>
                    <TableCell style={{ color: 'green' }}>
                      {sInvoice.atab_payment_invoice_total_amount}
                    </TableCell>
                    <TableCell style={{ color: 'red' }}>
                      {sInvoice.atab_payment_invoice_due}
                    </TableCell>
                    <TableCell style={{ color: 'red' }}>
                      {Number(sInvoice.atab_payment_invoice_due) === 0
                        ? getStatusLabel('Paid')
                        : getStatusLabel('Unpaid')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title='View' arrow>
                        <Link
                          to={`/invoices/transactions/${sInvoice.atab_payment_invoice_id}`}
                        >
                          <IconButton color='inherit' size='small'>
                            <Visibility fontSize='small' />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box p={2}>
        {/* <TablePagination
          component='div'
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page && page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        /> */}
      </Box>
    </CardContent>
  );
};

export default CompanyInvoices;
