import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import dayjs, { Dayjs } from 'dayjs';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { Imembers } from 'src/Types/module/member/memberTypes';
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CreateMeeting({ handleClose, setAllMembers }) {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [meetingType, setMeetingType] = useState('');
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);

  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs());
  const [allMember, setAllMember] = useState<Imembers[]>([]);
  const [membersId, setMembersId] = useState<string[]>([]);

  const newTimeValue = timeValue.add(6, 'hour');
  useEffect(() => {
    (async function () {
      try {
        let uri = `${url}/api/atab/user-member/get/all/by/zone/status/for-admin/all/all?skip=0&limit=10000`;

        const { data } = await axios.get(uri);

        if (data.success) {
          setAllMember(data.data);
        }
      } catch (error) {}
    })();
  }, []);

  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    if (!meetingType) {
      Toast.fire({
        icon: 'error',
        title: `Please select meeting type`,
      });
      setIsLoading(false);
      return;
    }
    if (dateValue && timeValue) {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (inputs[key]) {
          if (typeof inputs[key] === 'object') {
            if (inputs[key][0]) bodyFormData.append(key, inputs[key][0]);
          } else {
            bodyFormData.append(key, inputs[key]);
          }
        }
      }

      bodyFormData.append(
        'member_meeting_time',
        newTimeValue.toISOString().slice(11, 19)
      );
      bodyFormData.append(
        'member_meeting_date',
        dateValue.toISOString().split('T')[0]
      );
      bodyFormData.append(
        'member_meeting_created_by',
        user.user_admin_id.toString()
      );

      if (meetingType) bodyFormData.append('member_meeting_type', meetingType);
      const selectedMember = membersId.map((member) => {
        return parseInt(member.split(' ')[0]);
      });
      if (selectedMember?.length)
        bodyFormData.append('members_id', JSON.stringify(selectedMember));

      try {
        const { data } = await axios.post(
          `${url}/api/member/meeting/create/for-admin?user_admin_id=${user.user_admin_id}`,
          bodyFormData
        );

        if (data.success) {
          setAllMembers((prev) => [
            {
              member_meeting_id: data.data.member_meeting_id,
              member_meeting_title: inputs.member_meeting_title,
              member_meeting_date: dateValue.toISOString().split('T')[0],
              member_meeting_time: newTimeValue.toISOString().slice(11, 19),
              member_meeting_status: 'upcoming',
              meeting_created_by_id: user.user_admin_id.toString(),
              meeting_created_by_name: user.user_admin_name,
            },
            ...prev,
          ]);
          handleClose();
          Toast.fire({
            icon: 'success',
            title: `Create Successfully`,
          });
          setIsLoading(false);
          reset();
        }
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create meeting</title>
      </Helmet>

      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={{ xs: 2 }} mb={2}>
          {' '}
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label='Member meeting title'
              required
              id='outlined-required'
              aria-invalid={errors.member_meeting_title ? 'true' : 'false'}
              helperText={
                errors.member_meeting_title?.type === 'required' &&
                'Meeting title is required'
              }
              type='text'
              {...register('member_meeting_title', { required: true })}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label='Member meeting place'
              required
              id='outlined-required'
              type='text'
              aria-invalid={errors.member_meeting_place ? 'true' : 'false'}
              helperText={
                errors.member_meeting_place?.type === 'required' &&
                'Meeting meeting is required'
              }
              {...register('member_meeting_place', { required: true })}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Meeting type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={meetingType}
                // aria-invalid={errors.member_meeting_title ? "true" : "false"}
                label=' Meeting type'
                onChange={(e) => setMeetingType(e.target.value)}
                required
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'specific'}>Specific</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {meetingType === 'specific' && (
            <Grid xs={12} md={12} lg={12} item>
              <Autocomplete
                multiple
                id='tags-filled'
                options={allMember.map(
                  (option) =>
                    option.user_member_id +
                    ' ' +
                    option.user_member_company_name
                )}
                freeSolo
                onChange={(_event, newValue) => {
                  setMembersId(newValue);
                }}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant='filled'
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select member'
                    placeholder='More..'
                  />
                )}
              />
            </Grid>
          )}
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              id='outlined-required'
              type='file'
              label='Upload a meeting image'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('member_meeting_img')}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label='Meeting date'
                format='MM/DD/YYYY'
                value={dateValue}
                onChange={(e: any) => setDateValue(e)}
                sx={{ width: '100%' }}
                // renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label='Time'
                value={timeValue}
                onChange={(e) => setTimeValue(e)}
                sx={{ width: '100%' }}
                // renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label='Member meeting details'
              multiline
              rows={4}
              id='outlined-required'
              type='text'
              aria-invalid={errors.member_meeting_detail ? 'true' : 'false'}
              helperText={
                errors.member_meeting_detail?.type === 'required' &&
                'Meeting details is required'
              }
              {...register('member_meeting_details')}
            />
          </Grid>
          {/* <Grid xs={12} md={6} lg={6} item>
            <TextField
              multiline
              placeholder='Type in meeting remark'
              minRows={5}
              fullWidth
              maxRows={16}
              required
              id='outlined-required'
              {...register('member_meeting_remark')}
            />
          </Grid> */}
        </Grid>
        {!isLoading ? (
          <Button type='submit' fullWidth variant='contained' disableElevation>
            Create Meeting
          </Button>
        ) : (
          <LoadingButton
            fullWidth
            loading
            loadingIndicator='Creating...'
            variant='contained'
            disabled
          >
            <span>Creating...</span>
          </LoadingButton>
        )}
      </Box>
    </>
  );
}

export default CreateMeeting;
