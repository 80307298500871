import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { HttpClient } from 'src/Helpers/http/http';
import { debounce } from 'lodash';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import { IExpenseType } from '../types/expenseTypes';
import CreateExpenseHead from '../components/CreateExpenseHead';
import ExpenseTableRow from '../components/ExpenseTableRow';
import CreateExpense from '../components/CreateExpense';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CommonDateRangePicker from 'src/common/CommonDateRangePicker';

const ExpenseList = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [allExpense, setAllExpense] = useState<IExpenseType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: '',
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  console.log(dateRange);
  const [refresh, setRefresh] = useState(false);
  const [filterItem, setFilterItem] = useState({
    expense_no: '',
  });
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
  });

  const page = parseInt(searchParams.get('page'));

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/expense?CompanyProfileId=1&limit=${limit}&skip=${limit * page}`;
        if (filterItem.expense_no) {
          uri += `&expense_no=${filterItem?.expense_no}`;
        }
        if (dateRange?.start_date && dateRange?.end_date) {
          uri += `&start_date=${dateRange?.start_date}&end_date=${dateRange?.end_date}`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAllExpense(res.data);
          setLoading(false);
          setTotal(res.total);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit, filterItem.expense_no, dateRange, refresh]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  // const handleStatus = (e: SelectChangeEvent<string>) => {
  //   searchParams.set("status", e.target.value);
  //   setSearchParams(searchParams);
  // };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  // const paginatedInvoice = applyPagination(allExpense, page, limit);

  const handelInputChange = debounce((value: string, key: string) => {
    setFilterItem((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }, 2000);
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={12} md={1}>
                  <Typography
                    fontWeight={'bold'}
                    variant='h6'
                    component='h6'
                    gutterBottom
                  >
                    Expense List
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={11}>
                  <Grid container spacing={1} justifyContent={'end'}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                      <TextField
                        sx={{ width: '100%' }}
                        label='Search by expense no'
                        id='outlined-basic'
                        type='search'
                        size='small'
                        variant='outlined'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handelInputChange(e.target.value, 'expense_no')
                        }
                      />{' '}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <CommonDateRangePicker
                        setDateRange={setDateRange}
                        dateRange={dateRange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                      <Button
                        size='medium'
                        onClick={() => setOpen(true)}
                        variant='contained'
                        sx={{ width: '100%' }}
                      >
                        Create Expense
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Expense No</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>Account</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : allExpense.length ? (
                      <>
                        {allExpense.map((item) => (
                          <ExpenseTableRow
                            key={item.id}
                            expenseData={item}
                            setAllExpense={setAllExpense}
                            allExpense={allExpense}
                            setRefresh={setRefresh}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component='div'
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: 'Create Expense' }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateExpense
                setRefresh={setRefresh}
                handelClose={() => setOpen(false)}
              />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExpenseList;
