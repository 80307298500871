import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import atab_logo from '../../../../../../assets/image/atab-logo.png';
import { IViewExpense } from '../types/expenseTypes';
import { toWords } from '../../../subModules/memberList/singleMember/PrintInformationForm/PrintSingleMoneyReceipt/InvoiceDemo';

type IProps = {
  response: IViewExpense;
  componentRef: React.MutableRefObject<any>;
};
const PrintExpense = ({ response, componentRef }: IProps) => {
  const calculateTotal = (data: any) => {
    return (
      data?.length && data?.reduce((sum, item) => sum + Number(item.amount), 0)
    );
  };

  return (
    <Box
      ref={componentRef}
      width={'100%'}
      height={'95vh'}
      sx={{
        position: 'relative',
        background: '#fff',
        padding: '10px 20px',
        maxWidth: '8.50in',
      }}
    >
      <Grid container>
        <Grid item xs={3}>
          {' '}
          <img
            src={`${atab_logo}`}
            alt='Atab Logo'
            loading='lazy'
            height='50px'
          />
        </Grid>
        <Grid item xs={7}>
          {' '}
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
              ATAB Head Office
            </Typography>
            <Typography sx={{ fontSize: '11px' }}>
              Sattara Center (15th Floor), 30/A Naya Paltan, VIP Road,
              Dhaka-1000.
            </Typography>
            <Typography sx={{ fontSize: '11px' }}>
              Tel: +880 2 48320728, 8332712, Email: atab@atab.org.bd,
              www.atab.org.bd
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <p
            style={{
              fontSize: '10px',
              marginTop: '41px',
            }}
          >
            Printing Date : {dayjs().format('DD/MM/YYYY')}
          </p>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item>
          <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
            Payment Voucher
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          my: '10px',
          alignItems: 'center',
        }}
      >
        <Typography>
          {''}{' '}
          <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
            Voucher No :
          </span>{' '}
          <span style={{ fontSize: '10px' }}>{response?.voucher_no}</span>
        </Typography>

        <Typography>
          <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
            Voucher Date:
          </span>{' '}
          <span style={{ fontSize: '10px' }}>
            {dayjs(response?.expense_date).format('DD/MM/YYYY')}
          </span>
        </Typography>
      </Box>

      <table className='table'>
        <thead>
          <tr className='td'>
            <th className='td' style={{ width: '76px' }}>
              Serial No.
            </th>
            <th className='td'>Account Head</th>
            <th className='td'>Description</th>
            <th className='td' style={{ width: '80px' }}>
              Debit
            </th>
            <th className='td' style={{ width: '80px' }}>
              Credit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='th'>1</td>
            <td className='th'>{response?.account_name}</td>
            <td className='th'>{response?.note}</td>
            <td className='th' style={{ width: '80px' }}>
              -
            </td>
            <td className='th' style={{ width: '80px' }}>
              {' '}
              {Number(response?.amount)}
            </td>
          </tr>
          {response?.items?.map((item, index) => {
            return (
              <tr>
                <td className='th'>{index + 2}</td>
                <td className='th'>{item?.name}</td>
                <td className='th'>{response?.note}</td>
                <td className='th' style={{ width: '80px' }}>
                  {Number(item?.amount)}
                </td>
                <td className='th' style={{ width: '80px' }}>
                  -
                </td>
              </tr>
            );
          })}

          <tr>
            <td className=''></td>
            <td className=''></td>
            <td className='' style={{ fontWeight: 'bold', textAlign: 'end' }}>
              {' '}
              Total :
            </td>
            <td className='th' style={{ width: '80px' }}>
              {calculateTotal(response?.items)}
            </td>
            <td className='th' style={{ width: '80px' }}>
              {Number(response?.amount)}
            </td>
          </tr>
        </tbody>
      </table>
      <Typography sx={{ fontSize: '12px', padding: '20px 0' }}>
        <span style={{ fontWeight: 'bold' }}>In Words: </span>
        {toWords.convert(response?.amount ? Number(response.amount) : 0)}
      </Typography>

      <Grid container spacing={8} sx={{ marginTop: '80px' }}>
        <Grid item xs={4}>
          <span>
            Executive-Accounts <br />
            ATAB
          </span>
        </Grid>
        <Grid item xs={4}>
          {' '}
          <span>
            Senior Executive-Accounts <br />
            ATAB
          </span>
        </Grid>
        <Grid item xs={4}>
          <span>
            Executive Secretary <br />
            ATAB
          </span>
        </Grid>
        <Grid item xs={4}>
          <span>
            Md. Shafiq Ullah Nantu <br />
            Finance Secretary, ATAB
          </span>
        </Grid>
        <Grid item xs={4}>
          <span>
            Afsia Jannat Salah <br />
            Secretary General, ATAB
          </span>
        </Grid>
        <Grid item xs={4}>
          <span>
            Abdus Salam Aref <br />
            President, ATAB
          </span>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrintExpense;
